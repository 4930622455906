import { Component, OnInit, TemplateRef, ViewChild, } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal'; //



@Component({
  selector: 'app-music',
  templateUrl: './music.component.html',
  styleUrls: ['./music.component.scss']
})
export class MusicComponent implements OnInit {
  modalRef: BsModalRef;
  @ViewChild('deeplinkModal') deeplinkModal: TemplateRef<any>;


  constructor(private apiService: ApiService, private modalService: BsModalService) { }

  ngOnInit() {
  }

  buttonClicked() {
    this.apiService.buyPasses();
  }

  buyJess() {
    window.open('https://www.showpass.com/krugofest-week1-thursday/');
  }

  buyCorb() {
    window.open('https://www.showpass.com/krugofest-week1-saturday/');
  }

  buySheepdogs(day) {
    if (day === 'friday') {
      window.open('https://www.showpass.com/krugofest-week2-friday/');
    } else if (day === 'saturday') {
      window.open('https://www.showpass.com/krugofest-week2-saturday/');
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,
      { class: 'modal-dialog-centered',
  });
  }

}
