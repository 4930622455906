import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-krugofest',
  templateUrl: './krugofest.component.html',
  styleUrls: ['./krugofest.component.scss']
})
export class KrugofestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(window).one('scroll', function () {
      const leftPos = $('.scroll').scrollLeft();
      $('.scroll').animate({scrollLeft: leftPos + 85}, 700);
      $('.scroll').animate({scrollLeft: leftPos - 85}, 850);
    });
  }

  learnMore() {
    window.open('https://reginafoodbank.ca/');
  }

}
