import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

  categories: any[] = [
    {
      src: '../../assets/images/partnership/Airlines.png', title: 'Airlines', id: 'Airlines',
      // tslint:disable-next-line: max-line-length
      details: 'Whether it’s a carefully planned leisure trip — or a business trip with some wiggle room for adventure — we get people excited about the things that they love doing and help them find more of it wherever they go. \n\n We offer a turn-key solution for airline partners to provide their travellers with access to destination-specific content and bookable offerings through their existing mobile, email and web products. \n \n Our platform is built to seamlessly integrate at various key touch points through the travel journey. We help airlines serve their travellers by seeding travel inspiration, helping them plan their trips and keeping them actively engaged once they arrive in their destination.',
      snippets: [
        {
          snippetHeader: '../../assets/images/partnership/88.svg',
          snippetBody: 'of travelers think it would be useful to have access to all their favorite content in one app.',
          snippetSource: 'Travelport Digital 2018 Mobile Travel Trends Report'
        },
        {
          snippetHeader: '../../assets/images/partnership/35.svg',
          snippetBody: 'of travellers have bought excursions in local destinations through mobile.',
          snippetSource: 'Travelport Digital 2019 Mobile Travel Trends Report'
        }
      ]
    },
    {
      src: '../../assets/images/partnership/Hospitality.png', title: 'Hospitality', id: 'Hospitality',
      // tslint:disable-next-line: max-line-length
      details: 'Over 50% of business travellers are looking for things to do on the road (beyond working)— a reason to arrive a day early or stay a day late. Work with Krugo to help these people find that reason. \n\n Think of our software as a modern version of the highly knowledgable and personable concierge. Our solution allows you to provide guests with highly curated recommendations for food, nightlife and experiences in a highly scalable way while also introducing new ancillary revenue streams. \n\n We help hotels to build a stronger digital relationship with guests by going above and beyond to enhance their overall travel experience. This creates new opportunities to promote repeat bookings.',
      snippets: [
        {
          snippetHeader: '../../assets/images/partnership/quotationMarks.svg',
          // tslint:disable-next-line: max-line-length
          snippetBody: 'Our research highlights a clear opportunity to engage U.S. travelers with enhanced features on mobile apps to reduce effort and provide a convenient, end to end travel experience.',
          snippetSource: 'Simon Ferguson, President and Managing Director of Travelport Americas'
        },
        {
          snippetHeader: '../../assets/images/partnership/quotationMarks.svg',
          // tslint:disable-next-line: max-line-length
          snippetBody: 'More than half of U.S. leisure travelers would prefer to use an app for planning and adding extras to their trips on-the-go.',
          snippetSource: 'Travelport Digital 2018 Mobile Travel Trends Report'
        }
      ]
    },
    {
      src: '../../assets/images/partnership/CreditCardRewards.png', title: 'Credit Card Rewards', id: 'Credit',
      // tslint:disable-next-line: max-line-length
      details: 'Having a bunch of credit card reward points piling up is the perfect motivator to start thinking about planning a trip. Pair that up with rich content and bookable offerings focused on destinations around North America? Now that sounds exciting. \n\n We make it easy to expand your digital rewards products to go beyond simply ‘where are you staying’ or ‘how are you going to get there’ to focus on the unique experiences that will actually motivate next-generation travellers to pack their bags. \n\n This lets you build a more meaningful relationship with members by engaging with them before they even start planning a trip by suggesting places they might want to go based on their preferences and all of the things that they actually like to do.',
      snippets: [
        {
          snippetHeader: '../../assets/images/partnership/43.svg',
          // tslint:disable-next-line: max-line-length
          snippetBody: 'Increasing numbers of consumers are finding travel inspiration (43%) and booking their trips (28%) through various mobile apps.',
          snippetSource: 'Travelport Digital 2019 Mobile Travel Trends Report'
        },
        {
          snippetHeader: '../../assets/images/partnership/quotationMarks.svg',
          // tslint:disable-next-line: max-line-length
          snippetBody: 'As the median-age traveler moves more toward the digital generation, mobile technologies will also take a front seat to innovation in the loyalty program space.',
          snippetSource: 'Skift Skift Megatrends 2019'
        }
      ]
    },
  ];

  selectedCategory = null;
  oldCategory = 'Airlines';

  constructor(private router: Router) { }

  ngOnInit() {
    setTimeout(() => {
      this.categoryButtonClicked('Airlines');
    }, 1000);
  }

  categoryButtonClicked(category) {
    this.clearButton(this.oldCategory);
    this.oldCategory = category;
    this.selectedCategory = this.categories.find(element => element.id === category);
    const name = document.getElementById(category + 'Button');
    name.classList.add('btnClicked');
    const icon = document.getElementById(category + 'Icon');
    icon.classList.add('svg');
  }

  clearButton(oldCategory) {
    const name = document.getElementById(oldCategory + 'Button');
    name.classList.remove('btnClicked');
    const icon = document.getElementById(oldCategory + 'Icon');
    icon.classList.remove('svg');
  }

  requestDemo() {
    this.router.navigate(['/support'], { fragment: 'contact' });
  }



}

