import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { ExploreService } from './explore.service';

@Injectable({
  providedIn: 'root'
})
export class EventGuardService implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | import('@angular/router').UrlTree | Observable<boolean
    | import('@angular/router').UrlTree> | Promise<boolean | import('@angular/router').UrlTree> {
    if (this.exploreService.eventSet === true) {
      return true;
    }
    this.router.navigate(['/']);
    return false;
  }

  constructor(private router: Router, private exploreService: ExploreService) { }
}
