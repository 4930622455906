<section class="hero">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-4 col-12 offset-lg-4">
        <p class="text-center meetCrew">Meet our Team</p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-12 offset-lg-2">
        <p class="text-center singleLine">We are Krugo and we live to help people chase memorable experiences wherever
          they go.</p>
      </div>
    </div>
    <div class="row teamPicRow">
      <div class="col-lg-10 col-12 offset-lg-1">
        <img src="./assets/images/company/team.png" class="img-fluid">
      </div>
    </div>
    <hr>
  </div>
</section>


<section class="mission" id="mission">
  <div class="container-fluid flex-column mission-container">
    <div class="row mission-row">
      <div class="col-lg-5">
        <img src="./assets/images/company/mission.png" class="img-fluid" id="core-icon">
        <p class="header-text">Our Mission</p>
        <p class="details-text">To get people excited about the things that they love doing and help them find more of
          it wherever they go.</p>
      </div>
      <div class="col-lg-5 offset-lg-2">
        <img src="./assets/images/company/vision.png" class="img-fluid" id="core-icon">
        <p class="header-text">Our Vision</p>
        <p class="details-text">To become the platform that ignites inspiration and simplifies planning for the
          real-life experiences that people all around the world enjoy.</p>
      </div>
    </div>
    <hr>
  </div>
</section>


<section class="team" id="team">
  <div class="container-fluid team-container">
    <div class="row text-left">
      <div class="col-lg-6">
        <p class="header-text">Our Team</p>
        <p class="details-text">Our team unites unique talents around a common goal of helping people connect with
          unforgettable experiences. Meet the people who make Krugo.</p>
      </div>
    </div>
    <div class="row">
      <div *ngFor="let person of people" class="col-lg-3 col-12">
        <div class="peopleCard" (click)="cardClicked(person)">
          <img src="{{person.src}}" class="img-fluid">
          <p class="name">{{person.name}}</p>
          <p class="position">{{person.position}}</p>
        </div>
      </div>
    </div>
    <hr>
  </div>
</section>

<section class="core-values">
  <div class="container-fluid core-container">
    <div class="row text-left">
      <div class="col-lg-12">
        <p class="header-text">Our Values</p>
      </div>
    </div>
    <section id="values">
      <div class="row core-row">
        <div class="col-lg-3 col-12 text-left">
          <img src="./assets/images/company/curiosity.png" class="img-fluid" id="core-icon">
          <p class="core-title">Curiosity</p>
          <figcaption class="core-text">We find ourselves asking what-if and pushing each other to think bigger. If
            there is a better way, we’ll be ready to embrace it. </figcaption>
        </div>
        <div class="col-lg-3 col-12 text-left">
          <img src="./assets/images/company/trust.png" class="img-fluid" id="core-icon">
          <p class="core-title">Trust</p>
          <figcaption class="core-text">We believe in the speed of trust. Ownership is enabled through autonomy and we
            count on each other to live up to our commitments.</figcaption>
        </div>
        <div class="col-lg-3 col-12 text-left">
          <img src="./assets/images/company/accountability.png" class="img-fluid" id="core-icon">
          <p class="core-title">Accountability</p>
          <figcaption class="core-text">We have a strong bias for action and rise to the occasion. Everyone is a leader
            and is expected to leave their mark. </figcaption>
        </div>
        <div class="col-lg-3 col-12 text-left">
          <img src="./assets/images/company/openness.png" class="img-fluid" id="core-icon">
          <p class="core-title">Openness</p>
          <figcaption class="core-text">We listen, speak candidly, and appreciate the perspective of our teammates,
            partners and clients.</figcaption>
        </div>
      </div>
    </section>
  </div>
</section>