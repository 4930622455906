<footer class="page-footer">
  <div class="container-fluid">
    <div class="row footer-row">
      <div class="col-md-6 col-12">
        <img src="./assets/images/Krugo Wordmark - Medium.svg" class="d-inline-block align-top" id="footer-logo"
          alt="Krugo Logo" [routerLink]="''">
        <div class="col-md-12 social-icons">
          <a href="https://www.instagram.com/krugoapp/?hl=en" target="_blank" class="socials"><img class="socialLogo"
              src="../../assets/images/footer/instagram.svg"></a>
          <a href="https://www.facebook.com/krugoapp/" target="_blank" class="socials"> <img class="socialLogo"
              src="../../assets/images/footer/facebook.svg"></a>
          <a href="https://twitter.com/KrugoApp?lang=en" target="_blank" class="socials"><img class="socialLogo"
              src="../../assets/images/footer/twitter.svg"></a>
          <a href="https://www.linkedin.com/company/krugoapp/" target="_blank" class="socials"><img
              class="socialLogo" src="../../assets/images/footer/linkedin.svg"></a>
          <a href="https://vimeo.com/user95896927" target="_blank" class="socials"><img class="socialLogo"
              src="../../assets/images/footer/vimeo.svg"></a>
          <a href="https://medium.com/krugo" target="_blank" class="socials"><img class="socialLogo"
              src="../../assets/images/footer/medium.svg"></a>
        </div>
      </div>
      <div class="col-md-2 col-lg-2 offset-lg-2 offset-md-2 col-4 nav-col">
        <a class="link-header">About</a>
        <ul class="list-unstyled">
          <li>
            <a [routerLink]="'/about'">App</a>
          </li>
          <li>
            <a [routerLink]="'/partners'">Partnerships</a>
          </li>
          <li>
            <a [routerLink]="'/company'">Company</a>
          </li>
          <li>
            <a [routerLink]="'/media'">Media</a>
          </li>
        </ul>
      </div>
      <!-- <div class="col-md-2 col-lg-2 col-4 nav-column mt-4">
        <a class="link-header" [routerLink] = "'media'">Media</a>
        <ul class="list-unstyled">
          <li>
            <a [routerLink]="'media'" fragment="latest-news">Latest News</a>
          </li>
          <li>
            <a [routerLink]="'media'" fragment="blog">Blog</a>
          </li>
        </ul>
      </div> -->
      <div class="col-md-2 col-lg-2 col-4 nav-column nav-col">
        <a class="link-header" [routerLink]="'support'">Support</a>
        <ul class="list-unstyled">
          <li> <a [routerLink]="'support'">FAQ</a></li>
          <li><a [routerLink]="'legal'">Terms & Policy</a></li>
          <li><a [routerLink]="'support'" fragment="contact">Contact</a></li>
        </ul>
      </div>
    </div>
    <hr>
  </div>
  <div class="container-fluid">
    <div class="footer-copyright">
      <p>Copyright &copy; 2020 Krugo Marketing Inc. All rights reserved </p>
    </div>
  </div>
</footer>