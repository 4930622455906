<button type="button" class="btn-primary" (click)="openModal(template)">{{label}}</button>

<ng-template #template>
  <div class="modal-close">
    <button type="button" id="x-button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div *ngIf="initialDisplay" class="initial-content">
        <div class="row">
          <div class="col-lg-12 main-content">
            <img src="./assets/images/bullhorn-large.svg" class="img-fluid" id="bullhorn" alt="Bullhorn logo">
            <p class="text-header">Get the Krugo app and start planning with friends</p>
            <p class="text-content">
              Send a link to your phone, or download the Krugo app directly from the <a
                href="https://itunes.apple.com/us/app/krugo/id1393726426?mt=8">App Store</a>
              or <a href="https://play.google.com/store/apps/details?id=com.krugo.krugo">Google Play</a>
            </p>


            <div class="input-num">
              <form ngNativeValidate #f="ngForm" (ngSubmit)="onSubmit(phoneNumber.value)">
                <input #phoneNumber class="num-box" type="tel" id="phone" pattern="[0-9]{10}" minlength="10"
                  maxlength="10" placeholder="10 Digit Phone Number" required="required" size="21" />
                <button class="send-button">Send</button>
              </form>
            </div>


            <p class="footer-text">
              By clicking “Send”, you agree to the <a href="https://krugoapp.com/#/legal/tos"
                (click)="modalRef.hide()">Terms of Service</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="sentDisplay" class="sent-content">
    <div class="modal-body">
      <div class="container-fluid">
        <div class="row" id="sentText">
          <div class="col-lg-12">
            <img src="./assets/images/bullhorn-large.svg" class="img-fluid" id="bullhorn" alt="Bullhorn logo">
            <p class="text-header">We've sent a download link to you! </p>
            <p class="sendText-content">
              Trouble receiving our message? You can download the Krugo app directly from the <a
                href="https://itunes.apple.com/us/app/krugo/id1393726426?mt=8">App Store</a>
              or <a href="https://play.google.com/store/apps/details?id=com.krugo.krugo">Google Play</a>
            </p>
            <button class="close-button" (click)="modalRef.hide()">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>