import { formatDate } from '@angular/common';

export default class DateHelper {
  static getTextForDateRange(dates: Date[]) {
    if (dates[0].toDateString() === dates[1].toDateString()) {
      return formatDate(dates[0], 'LLL d', 'en-us');
    } else {
      return formatDate(dates[0], 'LLL d', 'en-us') + ' - ' + formatDate(dates[1], 'LLL d', 'en-us');
    }
  }

  static getWeekendDates(startDate: Date): Date[] {

    while (!this.isWeekendDay(startDate)) {
      startDate.setDate(startDate.getDate() + 1);
    }

    const endDate = new Date(startDate);
    while (this.isWeekendDay(endDate)) {
      endDate.setDate(endDate.getDate() + 1);
    }

    // set to last valid weekend day
    endDate.setDate(endDate.getDate() - 1);

    return [startDate, endDate];
  }

  static isWeekendDay(date: Date): boolean {
    return date.getDay() === 5 || date.getDay() === 6 || date.getDay() === 0;
  }

  static checkYear(dates: Date[]) {
    const today = new Date();
    if (today.getFullYear() !== dates[0].getFullYear()) {
      return dates[0].getFullYear();
    } else {
      return '';
    }
  }
}
