import Event from '../../models/Event';
import { Performer } from '../../models/Performer';
import { City } from '../../models/City';
import * as moment from 'moment';

export class EventCardViewModel {

  categoryText: string;
  eventTypeText: string;
  eventTitle: string;
  venueTitle: string;
  venueAddress: string;
  ticketCount: number;
  lastUpdated: number;
  cityText: string;
  dateText: string;
  priceText: string;
  ticketsAvailable = true;
  imageUrl: string;
  events: Event[];

  constructor(events: Event[]) {
    this.events = events;
    const multipleEvents: boolean = events.length > 1;
    const firstEvent = events[0];
    this.categoryText = EventCardViewModel.getCategoryForPerformer(firstEvent.primaryPerformer);
    this.eventTitle = multipleEvents ? firstEvent.primaryPerformer.name : firstEvent.eventName;
    this.priceText = this.getEventPrice();
    this.venueTitle = firstEvent.venue.name;
    this.venueAddress = firstEvent.venue.address1;
    this.ticketCount = firstEvent.ticketCount;
    this.eventTypeText = firstEvent.primaryPerformer.eventType;
    this.lastUpdated = firstEvent.lastUpdated;
    this.cityText = City.getCityStateString(firstEvent.city);
    this.dateText = multipleEvents ?
      'Multiple Events' :
      EventCardViewModel.getEventDate(firstEvent) + ' ' + EventCardViewModel.getEventTime(firstEvent);
  }

  static generateViewModelsForEvents(events: Event[]): EventCardViewModel[] {
    const viewModels = [];
    const groupedByPerformerId = (events || []).reduce((g: any, event: Event) => {
      g[event.performerId] = g[event.performerId] || []; // Check the value exists, if not assign a new array
      g[event.performerId].push(event); // Push the new value to the array
      return g; // Very important! you need to return the value of g or it will become undefined on the next pass
    }, {});

    for (const pid in groupedByPerformerId) {
      // check if the property/key is defined in the object itself, not in parent
      if (groupedByPerformerId.hasOwnProperty(pid)) {
        viewModels.push(new EventCardViewModel(groupedByPerformerId[pid]));
      }
    }
    return viewModels;
  }

  static getCategoryForPerformer(performer: Performer): string {
    if (performer.primaryCategory) {
      return performer.primaryCategory.abbreviation;
    } else {
      let eventTypeString = performer.eventType;
      eventTypeString = eventTypeString[0].toUpperCase() + eventTypeString.slice(1);
      return eventTypeString;
    }
  }

  static getEventDate(event) {
    const timeStamp = event.eventDateString;
    if (event.eventDay === 'Multiple Events') {
      return event.eventDay;
    }
    const date = new Date(timeStamp);
    return date.toLocaleString('en-US', { weekday: 'short', day: 'numeric', month: 'numeric' });
  }

  static getEventTime(event) {
    const timeStamp = event.eventDateString;
    if (event.eventDay === 'Multiple Events') {
      return '';
    }
    const momentDate = moment(timeStamp);
    return ' at ' + momentDate.format('h:mm A');
  }

  getEventPrice(): string {
    const cheapestEvent = this.getCheapestEvent();
    if (cheapestEvent.minPrice === 0) {
      this.ticketsAvailable = false;
      return 'No Tickets Available';
    } else if (cheapestEvent.minPrice > 0) {
      if (cheapestEvent.minPrice % 1 === 0) {
        return '$' + cheapestEvent.minPrice + '.00';
      } else {
        return '$' + cheapestEvent.minPrice;
      }
    } else {
      return '';
    }
  }

  getCheapestEvent(): Event {
    // find the cheapest non-zero Event
    // if the result event's min price is 0, then there are no tickets available :(
    // console.log(this.events);
    return this.events.reduce((min, e) => ((e.minPrice < min.minPrice && e.minPrice > 0) || min.minPrice === 0) ? e : min, this.events[0]);
  }
}
