<body>
  <div class="container-fluid" id="terms">
    <div class="row">
      <div class="col-lg-10 offset-lg-1">
        <hr class=nav-divider>
        <p class="section-header">
          Terms and Conditions
        </p>
        <p class="content">
          In these terms, words like ‘holder’, ‘attendee’, and ‘purchaser’ will refer to you – the person who will buy
          the tickets to KrugoFest. Words such as ‘our’, ‘us’, ‘we’, ‘issuer’, ‘provider’ and similar terms will
          indicate the organisers of KrugoFest – Krugo Marketing Inc.
        </p>

        <p class="content">
          The holder of the ticket hereby agrees, and warrants that all other attendees entering KrugoFest will abide
          by, and agree to the following terms: <br> <br>
          <strong>1.</strong> All ticket packages for a single room will allow a maximum capacity, and provide entry to
          the venue, of
          four attendees;<br>
          <strong>2.</strong> Attendees will physically distance from all other persons at a distance of not less than 6
          feet when
          outside of their assigned rooms;<br>
          <strong>3.</strong> Except when present in assigned rooms, all attendees will wear face masks; including
          during check-in and
          exit;<br>
          <strong>4.</strong> No attendee will enter a room to which they are not assigned and agree they are prohibited
          from doing so;<br>
          <strong>5.</strong> Any person in violation of any public health order or other governmental order is not
          permitted to attend;<br>
          <strong>6.</strong> Smoking is not permitted indoors or outdoors, including the balconies, while on the
          premises of the venue.
          Should an attendee wish to leave the venue, they will comply with clause 2 at all times;<br>
          <strong>7.</strong> Glassware, glass bottles and cans are not permitted on balconies at any time -- please use
          plastic cups;<br>
          <strong>8.</strong> All attendees will refrain from leaning over the balconies, refrain from standing on
          chairs or other
          objects, and will keep two feet on the ground at all times;<br>
          <strong>9.</strong> Attendees are prohibited from throwing or dropping any objects or other items from the
          balconies;<br>
          <strong>10.</strong> All attendees consent to being photographed, recorded, broadcast or published via any
          media recorded while
          in attendance of KrugoFest, and hereby grant an unconditional, non-revocable interest free licence to Krugo
          Marketing Inc.<br>
        </p>

        <p class="content">
          Any attendees found to be in violation of these terms, or any other conditions declared: (a) at the event, or
          (b) by public health or other governmental order, may be removed from the venue or denied entry at the
          complete and total discretion of Krugo Marketing Inc or its Associates.
        </p>

        <p class="content">
          Attendees will indemnify, and hold harmless Krugo Marketing Inc and all Associates (including management,
          facilities, artists, other participants, and all of our respective parents, affiliated entities, agents,
          officers, directors, owners and employees) against any “Indemnified Claim,” meaning any third party claim,
          suit, or proceeding arising out of or related to Customer & alleged or actual attendance of, misuse of, or
          failure to attend the event, including without limitation, claims of personal injury or death.
        </p>

        <p class="content">
          Krugo Marketing Inc reserves the rights to cancel, postpone, or change the event in any way whatsoever, at
          their complete discretion, and will make best efforts to provide replacement tickets should such circumstances
          arise. In such circumstances whatsoever will any refunds be provided.
        </p>

        <p class="content">
          Attendees agree to comply with all applicable rules, policies, terms and conditions. Event organizers reserve
          the right, without refund of any amount paid, to refuse admission to, or eject, any person whose conduct
          management deems disorderly, who uses vulgar or abusive language or who fails to comply with applicable event
          rules, policies, terms and conditions.
        </p>

        <p class="content">
          Breach of terms or rules will terminate the attendees license to attend the event without refund.
        </p>


        <p class="content">
          Please contact <a href="mailto:krugofest@krugoapp.com">krugofest@krugoapp.com</a> for any questions.
        </p>
      </div>
    </div>
  </div>
</body>