import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';




@Component({
  selector: 'app-verifyemailexpired',
  templateUrl: './verifyemailexpired.component.html',
  styleUrls: ['./verifyemailexpired.component.scss']
})
export class VerifyEmailExpiredComponent implements OnInit {
  emailExpired = environment.emailExpired;

  constructor(private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
  }

}
