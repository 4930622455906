<section class="faqs" id="faq">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3 col-12">
        <div class="title">
          <p class="titleText">Frequently Asked Questions</p>
        </div>
        <div class="subTitle">
          <p class="subText">Want to learn a little bit more about what KrugoFest is? Check out this list of commonly
            asked questions.</p>
        </div>
      </div>
      <div class="col-lg-9 col-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse1">
              <hr class="line">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">
                  What time should I come? When does the show start?
                  <i class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse1" class="panel-collapse collapse in">
              <div class="panel-body">If you’ve purchased a ticket package, you will receive an email at least five days
                before the show to give you more details on the check-in process. You will also be assigned a specific
                check-in time between 5:30pm and 7:30pm. <br><br> You will be expected to arrive together at your
                check-in time.
                If you show up late, expect to wait. We’ll have the tunes going as soon as you arrive so soak it up if
                you arrive early. The formal show kicks off at 8pm.
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse2">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse2">
                  Can I stay the night at the hotel?
                  <i class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse2" class="panel-collapse collapse in">
              <div class="panel-body">Absolutely! Check-out time is 10am sharp!
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse3">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse3">
                  Can I bring my own food / alcohol?
                  <i class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse3" class="panel-collapse collapse in">
              <div class="panel-body">Yes you can but keep in mind each ticket package includes a meal. That said, you
                won’t ever regret bringing some snacks to munch on when the headliner comes out. If you choose to have a
                couple of beers or mix up a few cocktails during the show, we encourage you to stay the night or arrange
                a sober ride home.
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse4">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse4">
                  Will I be able to buy alcohol at the event?
                  <i class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse4" class="panel-collapse collapse in">
              <div class="panel-body">Due to COVID-19, alcohol will not be sold.
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse5">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse5">
                  Does this event run multiple days?
                  <i class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse5" class="panel-collapse collapse in">
              <div class="panel-body">Yes, KrugoFest will run for multiple days; however, individual packages are sold
                for each specific night. Attendees will be checked out of their guestroom by 10am the morning following
                the show.
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse6">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse6">
                  How do I buy tickets to KrugoFest?
                  <i class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse6" class="panel-collapse collapse in">
              <div class="panel-body">You can buy packages on the <a [routerLink]="'../../about'" fragment="app">free
                  Krugo app</a>. Set your Explore city to Regina or simply search for “KrugoFest”. If you are on a
                non-mobile device, clicking on one of the "Buy Packages" buttons will redirect you to our ticket
                provider.
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse7">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse7">
                  If I can’t make it, can I get a refund?
                  <i class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse7" class="panel-collapse collapse in">
              <div class="panel-body">Unfortunately, tickets are non-refundable. If the primary ticket-holder (the one
                who bought the room) needs to be changed, please contact us at <a
                  href="mailto:krugofest@krugoapp.com">krugofest@krugoapp.com</a>.
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse8">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse8">
                  Is it safe to attend KrugoFest during the COVID-19 Pandemic?
                  <i class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse8" class="panel-collapse collapse in">
              <div class="panel-body">We’ve thought a lot about how to keep everyone safe at the end. <a
                  [routerLink]="'../safety'" fragment="safety">Click here</a> to
                learn more.
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse9">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse9">
                  Who is putting on KrugoFest?
                  <i class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse9" class="panel-collapse collapse in">
              <div class="panel-body">KrugoFest was created by the creators of Krugo: a mobile app designed to help you
                find things to do in thousands of different cities around North America. People haven’t been travelling
                much lately, though, so we thought it would be fun to team up with the Regina Food Bank to create this
                summer concert series.
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse10">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse10">
                  What if it rains?
                  <i class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse10" class="panel-collapse collapse in">
              <div class="panel-body">Bring your rain gear and umbrellas, because the show will go on! In the event of
                an electrical storm or high winds, attendees may be asked to stay in their rooms.
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse11">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse11">
                  My friends and I got two rooms. Can we all hang out in one room?
                  <i class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse11" class="panel-collapse collapse in">
              <div class="panel-body">Unfortunately, you’ll need to stick to the four person limit per room. Any guests
                found to be exceeding this limit will forfeit their room(s) and be immediately removed from the event.
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse12">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse12">
                  Anything else I should know about?
                  <i class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse12" class="panel-collapse collapse in">
              <div class="panel-body">Everything else you might want to know can be found in the <a
                  [routerLink]="'../../legal/festival'" fragment="terms">terms and conditions</a>
                but if not, you can always email us at <a
                  href="mailto:krugofest@krugoapp.com">krugofest@krugoapp.com</a>.
              </div>
            </div>
            <hr>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>