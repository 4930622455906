<nav class="navbar navbar-expand-lg navbar-dark" *ngIf="showNav">
  <a class="navbar-brand" [routerLink]="''"><img src="./assets/images/Krugo Wordmark - White.svg" width="130"
      height="45.28" class="d-inline-block align-top" alt="Krugo Logo"></a>
  <button class="navbar-toggler" type="button" aria-controls="navbarNavAltMarkup" (click)="openModal(navModal)"
    aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
    <div class="navbar-nav ml-auto">
      <a class="nav-item nav-link" [routerLink]="'/about'" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact:true}"><span>App</span></a>
      <a class="nav-item nav-link" [routerLink]="'/partners'" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact:true}"><span>Partnerships</span></a>
      <a class="nav-item nav-link" [routerLink]="'/company'" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact:true}"><span>Company</span></a>
      <a class="nav-item nav-link" [routerLink]="'/media'" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact:true}"><span>Media</span></a>
      <hr />
    </div>
  </div>
</nav>

<ng-template #navModal>
  <div class="modal-header">
    <a class="modal-title pull-left" [routerLink]="''" (click)="modalRef.hide()"><img
        src="./assets/images/Krugo Wordmark - Medium.svg"></a>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="navModal-body">
    <div class="text-center">
      <a class="nav-item nav-link active-span" [routerLink]="'/about'" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact:true}" (click)="modalRef.hide()"><span>App</span></a>
      <a class="nav-item nav-link" [routerLink]="'/partners'" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact:true}" (click)="modalRef.hide()"><span>Partnerships</span></a>
      <a class="nav-item nav-link" [routerLink]="'/company'" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact:true}" (click)="modalRef.hide()"><span>Company</span></a>
      <a class="nav-item nav-link" [routerLink]="'/media'" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact:true}" (click)="modalRef.hide()"><span>Media</span></a>
    </div>
  </div>
</ng-template>