import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ExploreService } from '../../services/explore.service';
import { EventCardViewModel } from '../explore/EventCardViewModel';
import MetricsHelper from '../utils/MetricsHelper';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { ExploreComponent } from '../explore/explore.component';
import { CookieService } from 'ngx-cookie-service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { City } from '../../models/City';


@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
  providers: [ExploreComponent]
})
export class EventDetailComponent implements OnInit {
  event: EventCardViewModel;
  limitedShowAmount = 6;
  upcomingEvents = [];
  multipleEvents;
  vividModalRef: BsModalRef;
  modalRef: BsModalRef;
  vividLink = '';
  showAll = false;
  clickedEvent;
  disableButton = false;

  @ViewChild('vividModal') vividModal: TemplateRef<any>;
  @ViewChild('multipleModal') private multipleModal: TemplateRef<any>;

  constructor(private exploreService: ExploreService, private exploreComponent: ExploreComponent,
    private breakpointObserver: BreakpointObserver, private cookieService: CookieService, private modalService: BsModalService) { }

  ngOnInit() {
    this.event = this.exploreService.getEvent();
    this.upcomingEvents = this.filterRelatedEvents(this.exploreService.getEventArray());
    this.vividLink = this.event.events[0].url;

    if (this.upcomingEvents.length < this.limitedShowAmount) {
      this.showAll = true;
    } else {
      this.showAll = false;
    }
  }

  getUpdatedTime() {
    const minutes = Math.floor((Date.now() - (this.event.lastUpdated * 1000)) / 60000);
    if (minutes > 30) {
      return '> 30 minutes ago';
    } else if (minutes < 2) {
      return 'Less than a minute ago';
    } else {
      return minutes + ' minutes ago';
    }
  }

  getEventPrice() {
    if (this.event.priceText !== 'No Tickets Available') {
      const price = this.event.priceText;
      this.disableButton = false;
      return price.substring(0, price.length - 3) + ' / ticket';
    } else {
      this.disableButton = true;
      return this.event.priceText;
    }
  }

  getEventCity() {
    return this.exploreService.cityText.toUpperCase().substring(0, this.exploreService.cityText.length - 4);
  }

  getEventDates() {
    return this.exploreComponent.selectedDates;
  }

  observeBreakpoints() {
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe((s: BreakpointState) => {
        for (const breakpointsKey in s.breakpoints) {
          if (s.breakpoints[breakpointsKey]) {
            if (breakpointsKey === Breakpoints.Large || breakpointsKey === Breakpoints.XLarge) {
              this.limitedShowAmount = 8;
            } else {
              this.limitedShowAmount = 6;
            }
          }
        }
      });
  }

  eventTapped(eventCardViewModel: EventCardViewModel) {
    this.exploreService.setEvent(eventCardViewModel);
    this.exploreService.setEventArray(this.upcomingEvents);
    if (eventCardViewModel.events.length > 1) {
      this.sortMultipleEventsbyDate(eventCardViewModel.events);
      this.multipleEvents = EventCardViewModel.generateViewModelsForEvents(eventCardViewModel.events);
      this.clickedEvent = eventCardViewModel;
      this.openModal(this.multipleModal);
    } else {
      this.event = eventCardViewModel;
      this.vividLink = eventCardViewModel.events[0].url;
      window.scrollTo(0, 0);
    }
  }

  sortMultipleEventsbyDate(multipleEvents) {
    multipleEvents.sort((a, b) => a.eventDate - b.eventDate);
  }

  getVividLink(event) {
    this.vividLink = event.url;
  }

  hasVividCookie() {
    return this.cookieService.check('vividCookie');
  }

  setVividCookie() {
    this.cookieService.set('vividCookie', 'dismiss');
  }

  openModal(template: TemplateRef<any>) {
    const config: ModalOptions = { class: 'modal-lg' };
    this.modalRef = this.modalService.show(template, config);
  }

  openVividModal(template: TemplateRef<any>) {
    this.vividModalRef = this.modalService.show(template);
  }

  getMultipleTime(plan) {
    const timeStamp = plan.eventDateString;
    const momentDate = moment(timeStamp);
    return ' at ' + momentDate.format('h:mm A');
  }

  getMultipleDate(plan) {
    const timeStamp = plan.eventDateString;
    const date = new Date(timeStamp);
    return date.toLocaleString('en-US', { weekday: 'short', day: 'numeric', month: 'numeric' });
  }

  buyTickets() {
    MetricsHelper.logEvent('Buy_Tickets_Tapped');
    if (!this.hasVividCookie()) {
      this.openVividModal(this.vividModal);
    } else {
      window.open(this.vividLink);
    }
  }

  getPlanCost(plan) {
    if (plan.minPrice > 0) {
      if (plan.minPrice % 1 === 0) {
        return '$' + plan.minPrice + '.00';
      } else {
        return '$' + plan.minPrice;
      }
    } else {
      return 'No Tickets Available';
    }
  }

  getMultipleModalAbbreviation(event) {
    if (event.primaryPerformer.primaryCategory === null) {
      const eventType = event.primaryPerformer.eventType;
      return eventType[0].toUpperCase() + eventType.slice(1);
    } else {
      return event.primaryPerformer.primaryCategory.abbreviation;
    }
  }

  multipleEventClicked(event, index) {
    const newEvent = event[0].events[index];
    setTimeout(() => {
      this.event.categoryText = event.categoryText;
      this.event.eventTitle = newEvent.eventName;
      this.event.priceText = this.GetEventPriceString(newEvent);
      this.event.venueTitle = newEvent.venue.name;
      this.event.venueAddress = newEvent.venue.address1;
      this.event.ticketCount = newEvent.ticketCount;
      this.event.cityText = City.getCityStateString(newEvent.city);
      this.event.dateText = this.getEventDate(newEvent) + ' ' + this.getEventTime(newEvent);
      this.event.imageUrl = this.clickedEvent.imageUrl;
      this.vividLink = newEvent.url;
      window.scrollTo(0, 0);
      this.modalRef.hide();
    }, 100);
  }

  getEventDate(event) {
    const timeStamp = event.eventDateString;
    if (event.eventDay === 'Multiple Events') {
      return event.eventDay;
    }
    const date = new Date(timeStamp);
    return date.toLocaleString('en-US', { weekday: 'short', day: 'numeric', month: 'numeric' });
  }

  getEventTime(event) {
    const timeStamp = event.eventDateString;
    if (event.eventDay === 'Multiple Events') {
      return '';
    }
    const momentDate = moment(timeStamp);
    return ' at ' + momentDate.format('h:mm A');
  }

  GetEventPriceString(event): string {
    if (event.minPrice === 0) {
      this.event.ticketsAvailable = false;
      return 'No Tickets Available';
    } else if (event.minPrice > 0) {
      if (event.minPrice % 1 === 0) {
        return '$' + event.minPrice + '.00';
      } else {
        return '$' + event.minPrice;
      }
    } else {
      return '';
    }
  }

  filterRelatedEvents(eventArray) {
    // tslint:disable-next-line: max-line-length
    const relatedEvents = eventArray.filter(event => event.categoryText === this.event.categoryText || event.eventTypeText === this.event.eventTypeText);
    const duplicateEvent = relatedEvents.find(element => element.eventTitle === this.event.eventTitle);
    const indexOfDupe = relatedEvents.indexOf(duplicateEvent);
    if (indexOfDupe > -1) {
      relatedEvents.splice(indexOfDupe, 1);
    }
    return relatedEvents;
  }
}
