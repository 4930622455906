import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  activePerson: any;
  people: any[] = [
    {
      src: './assets/images/company/headshots2/kirk.png', name: 'Kirk Morrison',
      position: 'CEO / Cofounder', profile: 'https://www.linkedin.com/in/kirk-morrison-a8128830/'
    },
    {
      src: './assets/images/company/headshots2/andrew.png', name: 'Andrew Cretin',
      position: 'CTO / Cofounder', profile: 'https://www.linkedin.com/in/andrewcretin/'
    },
    {
      src: './assets/images/company/headshots2/kale.png', name: 'Kale Baiton',
      position: 'VP, Development', profile: 'https://www.linkedin.com/in/kbaiton/'
    },
    {
      src: './assets/images/company/headshots2/conor.png', name: 'Conor Christie',
      position: 'UI / UX Lead', profile: 'https://www.linkedin.com/in/conor-christie-a62b7ab1/'
    },
    {
      src: './assets/images/company/headshots2/lindsey.png', name: 'Lindsey Isaak',
      position: 'VP, Development', profile: 'https://www.linkedin.com/in/lindsey-isaak-9a872757/'
    },
    {
      src: './assets/images/company/headshots2/kevin.png', name: 'Kevin Baker',
      position: 'Software Developer', profile: 'https://www.linkedin.com/in/kevin-baker-919236b0/'
    },
    {
      src: './assets/images/company/headshots2/paul.png', name: 'Paul Hewitt',
      position: 'Software Developer', profile: 'https://www.linkedin.com/in/p-hewitt/'
    },
    {
      src: './assets/images/company/headshots2/shalom.png', name: 'Shalom Mathews',
      position: 'Software Developer', profile: 'https://www.linkedin.com/in/shalom-mathews/'
    },
    {
      src: './assets/images/company/headshots2/josh.png', name: 'Josh Piett',
      position: 'Marketing / Design Coordinator', profile: 'https://www.linkedin.com/in/joshpiett/'
    },
    {
      src: './assets/images/company/headshots2/gillian.png', name: 'Gillian Delgaty',
      position: 'Growth Coordinator', profile: 'https://www.linkedin.com/in/gillian-delgaty-07bb27172/'
    },
    {
      src: './assets/images/company/headshots2/krugobot.png', name: 'Trippy',
      position: 'VP, Ideas', profile: 'https://www.linkedin.com/company/krugoapp/'
    }
  ];

  constructor() { }

  ngOnInit() {
  }

  cardClicked(person) {
    window.open(person.profile);
  }

}
