<body>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-10 offset-lg-1">
        <hr class=nav-divider>
        <p class="section-header">
          Contest Rules
        </p>

        <p class="content">
          NO PURCHASE IS NECESSARY. <br>
          Contest void where prohibited.
        </p>

        <p class="sub-header">
          1. Contest Terms:
        </p>

        <p class="content">
          Contest commences at 12:00:00 p.m. M.S.T. December 9 2019 and closes at 11:59:59 p.m. M.S.T. December 23, 2019.
        </p>

        <p class="sub-header">
          2. Eligibility:
        </p>

        <p class="content">
          Entrants must be eighteen (18) years age or older by the contest closing date and be a legal resident of the
          50 United States, the District of Columbia or Canada (excluding Quebec). Employees of Krugo Marketing Inc. and
          their immediate families are NOT eligible to participate in this contest.

        </p>

        <p class="sub-header">
          3. Entry
        </p>

        <p class="content">
          Entry in the contest requires the individual to be a registered Krugo user. MAXIMUM OF ONE USER ACCOUNT PER
          INDIVIDUAL. PERSONS WITH MULTIPLE ACCOUNTS WILL NOT BE ELIGIBLE TO WIN. Required entry information includes
          correct and valid last name, first name and phone number. Multiple entries are not permitted. All entries
          become the sole property of Krugo Marketing Inc. and none will be returned.
        </p>

        <p class="sub-header">
          4. Prize
        </p>

        <p class="content">
          The prize will consist of a $250 credit valued at approximately $250 USD. An equal amount of gift
          certificates or cash payment by check may also be substituted by Krugo Marketing Inc. in lieu of the prize.
          Approximate Retail Value of the grand prize is $250 US. No warranty is provided on this prize. No requests
          for refund or substitution of any type are allowed. All taxes, duties, fees and surcharges associated with the
          prize are the responsibility of the winner.
        </p>

        <p class="sub-header">
          5. Prize Draw
        </p>

        <p class="content">
          A random drawing will be conducted on a date within 2 weeks of Contest Close Date by Krugo Marketing Inc.,
          whose decisions are final. Each entrant selected as a winner must comply with all terms and conditions set
          forth in these rules and regulations, and winning is dependent upon fulfilling all such requirements. Winners
          will be notified by email or phone within ten (10) days of the drawing. Krugo Marketing Inc. is not
          responsible for notifications that are misdirected or not received because of email addresses or phone numbers
          that are not correct. If a winner cannot be contacted within fourteen (14) days after the first attempt to
          contact such winner, an alternate entrant will be selected at random from all entries received. If the prize
          notification is returned as undeliverable, the winner will be disqualified and another winner selected in the
          original winner's place. Winners may be required to sign and return an affidavit of eligibility within
          fourteen (14) days of the first notification attempt. Winners who are located in Canada will be required to
          answer a time-limited, mathematical skill-testing question, without assistance, to be awarded the prize.
        </p>

        <p class="sub-header">
          6. Conditions
        </p>

        <p class="content">
          By accepting the prize, the winner agrees that Krugo Marketing Inc. may use the winner's name for advertising
          and promotional purposes without further consideration, unless prohibited by law. BY PARTICIPATING IN THE
          CONTEST, THE PARTICIPANT AGREES THAT Krugo Marketing Inc., ITS SUBSIDIARIES, ITS AFFILIATES AND ALL OF Krugo
          Marketing Inc.'s OFFICERS, DIRECTORS, EMPLOYEES AND REPRESENTATIVES, WILL HAVE NO LIABILITY WHATSOEVER FOR,
          AND WILL BE HELD HARMLESS FOR ANY AND ALL LIABILITY FOR ANY INJURY, LOSS OR DAMAGES OF ANY KIND TO PERSONS,
          INCLUDING DEATH, AND PROPERTY, DUE IN WHOLE OR IN PART, DIRECTLY OR INDIRECTLY, FROM THE ACCEPTANCE,
          POSSESSION, USE OR MISUSE OF THE PRIZE OR PARTICIPATION IN THIS CONTEST OR ANY PRIZE RELATED ACTIVITY. Subject
          to all federal, state and local laws. Void where prohibited.
        </p>

        <p class="sub-header">
          7. Other
        </p>

        <p class="content">
          In the event of a dispute, all entries received will be considered to be made by the authorized account holder
          of the email address on record at the time of contest ending. The "authorized account holder" is deemed to be
          the natural person who is assigned to an email address by an internet service provider or other online
          organization that is responsible for assigning email addresses for the domain associated with the submitted
          email address. A winner may be requested to provide Krugo Marketing Inc. with proof that the winner is the
          authorized account holder of the email address associated with the winning entry along with proof of personal
          identification.
        </p>

        <p class="sub-header">
          8. Contest Disclaimer
        </p>

        <p class="content">
          Krugo Marketing Inc. reserves the right to make revisions to, cancel or suspend this contest for any reason
          including but not limited to a virus, bug or other cause beyond the reasonable control of Krugo Marketing Inc.
          which affects the administration or security of the contest. Any attempt to deliberately damage any product or
          to undermine the legitimate operation of this promotion is a violation of criminal and civil laws, and should
          such an attempt be made, Krugo Marketing Inc. will aim to seek remedies and damages to the fullest extent
          permitted by law, including criminal prosecution. This Contest is subject to all applicable federal,
          provincial and local laws. The Contest and these Official Rules shall be governed by and construed under
          Saskatchewan law, without regard to conflict of laws principles. In the event of a dispute, winners may be
          required to provide proof that they are the authorized account holders associated with the entry.
        </p>

        <p class="content">
          Please contact <a href="mailto:hello@krugoapp.com">hello@krugoapp.com</a>  for any questions.
        </p>

      </div>
    </div>
  </div>
</body>
