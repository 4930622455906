import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {ImageMetadata} from '../models/ImageMetadata';


@Injectable()
export class KrugoImageService {

  protected http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  protected createHeaders(service: string): object {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Service', service);
    headers = headers.set('Platform', 'API_Web');
    headers = headers.set('Version', '1');

    return {
      headers: headers
    };
  }

  public getTodayImagesForIds(ids: string[]): Observable<ImageMetadata[]> {
    const url = `${environment.routerBaseUrl}/get-images`;
    const headers = this.createHeaders('web-image');
    const params = new HttpParams();
    headers['params'] = params.set('ids', ids.join());
    return this.http.get<any>(url, headers);
  }

  public getUpcomingImagesForIds(ids: string[]): Observable<ImageMetadata[]> {
    const url = `${environment.routerBaseUrl}/get-images`;
    const headers = this.createHeaders('web-image');
    const params = new HttpParams();
    headers['params'] = params.set('ids', ids.join());
    return this.http.get<any>(url, headers);
  }

  // public getImagesWithHash(ids: string[], ExistingMD5Hashes: string[]): Observable<ImageMetadata[]>{
  //   const url = `${environment.routerBaseUrl}/get-images`;
  //   const headers = this.createHeaders('web-image');
  //   const params = new HttpParams();
  //   headers['params'] = params.set('ids', ids.join());
  //   headers['params'] = params.append('ExistingMD5Hases', ExistingMD5Hashes.join());
  //   console.log(headers['params']);
  //   return this.http.get<any>(url, headers);
  // }

}
