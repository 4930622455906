<section class="planHero">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-6 align-self-center">
        <p class="planningTitle">What's the Plan?</p>
        <div class="row">
          <div class="col-12 col-lg-6">
            <p class="detailHeader">August 6, 8, 14, 15</p>
            <p class="details">Dates<p>
          </div>
          <div class="col-12 col-lg-6">
            <p class="detailHeader">6-8pm</p>
            <p class="details">Check-in<p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <p class="detailHeader">Rooftop Festival</p>
            <p class="details">Event<p>
          </div>
          <div class="col-12 col-lg-6">
            <p class="detailHeader">Regina Food Bank</p>
            <p class="details">Proceeds<p>
          </div>
        </div>
        <button class="btn-primary" (click)="buttonClicked()">Buy Packages</button>
      </div>
      <div class="col-12 col-lg-6 align-self-center">
        <img class="img-fluid img" src="../../../assets/images/krugofest/Plan.png">
      </div>
    </div>
    <div class="row textRow">
      <div class="col-lg-6 col-12">
        <p class="planningDetails">Safety doesn’t have to suck. KrugoFest is the summer party you have been waiting for.
          Great music, fantastic food and cold drinks all while keeping your sweaty neighbours at a cool distance.
          <br><br>
          Everyone gets the VIP treatment with their own room at the DoubleTree by Hilton™ with a private balcony that
          looks out over our rooftop stage. You and your bubble buddies will feast on dinner prepared by local chefs
          while jamming to some of Saskatchewan’s hottest artists.</p>
      </div>
      <div class="col-lg-6 col-12">
        <p class="planningDetails"> It gets better! You’re not just feeding your soul, you’re helping feed your
          neighbours too. Part of the proceeds from every ticket package go right to the Regina Food Bank. <br><br>
          Listen,
          COVID-19 has changed everything about our community and our world. But, we are coming together to celebrate
          better days ahead and help those
          who need it most.
        </p>
      </div>
    </div>
  </div>
  <hr class="bottomDivider">
</section>

<section class="planDetails">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-6">
        <p class="planningTitle">Neighbours Feeding Neighbours</p>
        <p class="planningDetails">COVID-19 has presented several challenges for the Regina Food Bank including a
          drop
          in food donations, volunteer hours and surging food insecurity. In the months ahead, over 13,000 people
          will
          rely on their services. It is our goal to raise over $75,000 for the Regina Food Bank which equates to
          funding
          225,000 meals for people in need.</p>
      </div>
      <div class="col-12 col-lg-6">
        <p class="planningTitle">Health and Safety</p>
        <p class="planningDetails">This event is designed to meet and exceed all the public health orders from the
          SHA. Staggered check-in times, coordinated departures and private rooms will ensure we all stay safe. <a
          [routerLink]="'../safety'" fragment="safety">Click here</a> to learn more. <br><br> Volunteers and staff
          will ensure you have a safe and memorable night. You'll be asked to bring your patience and follow the rules
          so we can all enjoy this unique event experience.</p>
      </div>
    </div>
  </div>
  <hr class="bottomDivider">
</section>