import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';



@Component({
  selector: 'app-emailverified',
  templateUrl: './emailverified.component.html',
  styleUrls: ['./emailverified.component.scss']
})
export class EmailVerifiedComponent implements OnInit {
  emailVerified = environment.emailVerified;

  constructor(private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {

  }

}
