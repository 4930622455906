import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { formatDate } from '@angular/common';
import { TypeaheadResult } from '../models/TypeaheadResult';
import { EventCardViewModel } from '../app/explore/EventCardViewModel';
import Event from '../models/Event';



@Injectable()
export class ExploreService {
  lng = 0;
  lat = 0;
  event: EventCardViewModel;
  eventArray = [];
  eventSet: boolean;
  todayEvents = [];
  upcomingEvents = [];
  searchUpcomingDateRange = '';
  cityText = '';
  bannerURL = '';
  seenModal = false;
  random = Math.floor(Math.random() * (7 - 0 + 1) + 0);


  protected http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  protected createHeaders(service: string): object {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Service', service);
    headers = headers.set('Platform', 'API_Web');
    headers = headers.set('Version', '1');

    return {
      headers: headers
    };
  }

  protected createMinimumHeaders(): object {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Platform', 'API_iOS');
    headers = headers.set('Version', '1');

    return {
      headers: headers
    };
  }

  protected createCityHeaders(minimumVersion): object {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Platform', 'API_Web');
    headers = headers.set('Version', minimumVersion);
    headers = headers.set('Service', 'web-explore');

    return {
      headers: headers
    };
  }

  public getExplorePlans(exp, id): Observable<any> {
    const url = `${environment.routerBaseUrl}/get-explore-plans?expiryDate=` + exp + `&id=` + id;
    const headers = this.createHeaders('web-explore');
    return this.http.get<any>(url, headers);
  }

  public getNearbyPlans(lat, lng): Observable<any> {
    const date = this.getCurrentDate();
    // switch this to query params
    // tslint:disable-next-line: max-line-length
    const url = `${environment.routerBaseUrl}/get-nearby-events?lng=` + lng + `&radius=50000&lat=` + lat + `&startDate=` + date + '&endDate=';
    const headers = this.createHeaders('web-explore');
    return this.http.get<any>(url, headers);
  }

  public getNearbyEvents(lat, lng, dates: Date[]): Observable<Event[]> {
    const startDate = formatDate(dates[0], 'yyyy-MM-dd', 'en');
    const endDate = formatDate(dates[1], 'yyyy-MM-dd', 'en');
    // switch this to query params
    // tslint:disable-next-line: max-line-length
    const url = `${environment.routerBaseUrl}/get-nearby-events?lng=` + lng + `&radius=50000&lat=` + lat + `&startDate=` + startDate + '&endDate=' + endDate;
    const headers = this.createHeaders('web-explore');
    return this.http.get<Event[]>(url, headers);
  }

  public getTypeaheadSearch(searchString): Observable<TypeaheadResult> {
    const url = `${environment.routerBaseUrl}/typeahead-search?searchString=` + searchString;
    const headers = this.createHeaders('web-search');
    return this.http.get<TypeaheadResult>(url, headers);
  }

  public getUpcomingPlans(lat, lng): Observable<any> {
    const date = this.getCurrentDate();
    const endDate = this.getUpcomingDate();
    const url = `${environment.routerBaseUrl}/get-nearby-events?lng=` + lng
      + `&radius=50000&lat=` + lat + `&startDate=` + date + `&endDate=` + endDate;
    const headers = this.createHeaders('web-explore');
    return this.http.get<any>(url, headers);
  }

  public getCurrentDate() {
    return formatDate(new Date(), 'yyyy-MM-dd', 'en');
  }

  public getMinDate() {
    const minDate = new Date();
    return minDate;
  }

  public getUpcomingDate() {
    const date = this.getCurrentDate();
    const endDate = new Date(date);
    endDate.setDate(endDate.getDate() + 10); // getting the next 10 days
    const formattedEndDate = formatDate(endDate, 'yyyy-MM-dd', 'en');
    return formattedEndDate;
  }

  public getISODate() {
    const date = new Date(Date.now()).toISOString;
    return date;
  }

  public getMinimumVersion(): Observable<any> {
    const url = `${environment.routerBaseUrl}/minimum-version`;
    const headers = this.createMinimumHeaders();
    return this.http.get<any>(url, headers);
  }

  public getCityfromCoords(lat, lng, minimumVersion): Observable<any> {
    const url = `${environment.routerBaseUrl}/get-city-from-coordinates?Lng=` + lng + `&Lat=` + lat;
    const headers = this.createCityHeaders(minimumVersion);
    return this.http.get<any>(url, headers);
  }

  public updateTicketDetails(eventIds): Observable<any> {
    const url = `${environment.routerBaseUrl}/event-ticket-details`;
    const headers = this.createHeaders('web-explore');
    const data = {
      'eventIds': eventIds
    };
    return this.http.post<any>(url, data, headers);
  }

  public setEvent(event) {
    this.event = event;
    this.eventSet = true;
  }

  public getEvent() {
    return this.event;
  }

  public setEventArray(array) {
    this.eventArray = array;
  }

  public getEventArray() {
    return this.eventArray;
  }

  public setImageURL(url) {
    this.event.imageUrl = url;
  }

  public setTodayEvents(events) {
    this.todayEvents = events;
  }

  public getTodayEvents() {
    return this.todayEvents;
  }

  public setUpcomingEvents(events) {
    this.upcomingEvents = events;
  }

  public getUpcomingEvents() {
    return this.upcomingEvents;
  }

  public setUpcomingDateRange(dateString) {
    this.searchUpcomingDateRange = dateString;
  }

  public getUpcomingDateRange() {
    return this.searchUpcomingDateRange;
  }

  public setCityText(cityText) {
    this.cityText = cityText;
  }

  public getCityText() {
    return this.cityText;
  }

  public setBannerURL(bannerURL) {
    this.bannerURL = bannerURL;
  }

  public getBannerURL() {
    return this.bannerURL;
  }

}
