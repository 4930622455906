<section class="faqs" id="faq">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3 col-12">
        <div class="title">
          <p class="titleText">Frequently Asked Questions</p>
        </div>
        <div class="subTitle">
          <p class="subText">Want to learn a little bit more about what Krugo is? Check out this list of commonly asked
            questions.</p>
        </div>
      </div>
      <div class="col-lg-9 col-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse1">
              <hr class="line">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">
                  What is Krugo?<i
                    class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse1" class="panel-collapse collapse in">
              <div class="panel-body">Krugo makes it easy to find out what’s happening. Pick a city and we’ll show you
                the
                best concerts, sports, and shows happening tonight, tomorrow, or any dates you pick. Plus, we help you
                find
                deals on tickets! <br><br>

                Even better, tell us your favourite teams and/or artists and we’ll tell you when and where you can see
                them at the same time. Want to see a team or artist in a specific city? We do that too. <br><br>

                We’ll send you important updates, like when sports and tour schedules get released or when there is an
                awesome event coming near you. <br><br>

                Planning trips with your friends? Krugo can help you put together some itinerary ideas based on
                everyone’s
                favourites. Plan together and use the in-app chat to keep everyone on the same page.
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse2">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse2">
                  How much does it cost to use Krugo?
                  <i class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse2" class="panel-collapse collapse in">
              <div class="panel-body">Krugo is our gift to you... it’s free! Click <a href="http://get.krugoapp.com">
                  here</a> to download the app for iOS or
                Android.
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse3">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse3">
                  Can I purchase event tickets using Krugo?
                  <i class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse3" class="panel-collapse collapse in">
              <div class="panel-body">Heck yes, you can. Krugo works with Vivid Seats to give you access to a huge
                inventory of event tickets. Whether you’re looking for a last minute deal or some premium seating, Krugo
                has you covered. Just click Buy Tickets anywhere in the app and we’ll show you the latest prices and
                inventory available from Vivid Seats. <br><br>

                And, no... we don’t mark-up the price :) But that said, we have deals with our partners that help us
                generate revenue when tickets are purchased through the app so if we can help you find a good deal, it’s
                a
                win-win for everyone. Once you’ve bought tickets, we’ll keep track of the purchase details so you can
                get
                even with your friends but you can expect to receive your tickets from Vivid Seats directly.<br><br>

                Have questions about tickets that you purchased through the app? Get in touch with our friends over at
                Vivid Seats - their customer service team is always ready to help! You can reach them <a
                  href="https://www.vividseats.com/vivid-seats-customer-service.html"> here</a>
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse4">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse4">
                  I was looking for a specific event but I couldn’t find it. What’s up with that?
                  <i class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse4" class="panel-collapse collapse in">
              <div class="panel-body">Well we don't always have EVERY event but we’re working on adding to our inventory
                each day! We’re working hard to get you the events that you deserve.
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse5">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse5">
                  Can I book vacation homes or condos using Krugo? How about hotels?
                  <i class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse5" class="panel-collapse collapse in">
              <div class="panel-body">Yes, you can book condos, hotels and vacation homes through the Krugo app. Krugo
                works with Booking.com to help you find the best deals on properties all around the world. <br><br>

                Have questions about a property booked through the app? Get in touch with the Booking.com <a
                  href="https://secure.booking.com/content/cs.en-gb.html?label=opensearch-plugin;sid=f88c1363575ffb0192694c875de97d4d;srpvid=b6e982bee29f0012&">
                  customer support squad</a>
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse6">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse6">
                  Can I use Krugo with my friends?
                  <i class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse6" class="panel-collapse collapse in">
              <div class="panel-body">Absolutely... Krugo was built for groups! Simply add your friends from your
                contact
                book to create a crew in the app. From there, everyone will receive a link to download the app and
                you’ll
                be off to the races. <br><br>

                Use the group chat in the app to keep everyone on the same page for whatever you’ve got planned. Browse
                and purchase your tickets and book hotels, condos or vacation homes.

              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse7">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse7">
                  I’m looking to create a full itinerary to keep my friends organized. Can I add my own events to a plan
                  created on Krugo?
                  <i class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse7" class="panel-collapse collapse in">
              <div class="panel-body">Yes! Create groups with your friends in Krugo and work together to build and share
                your own custom-built itineraries. Add your own custom events for dinner plans, bus rides, tee times,
                hikes, or anything else you’re trying to coordinate.
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse8">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse8">
                  Where do I download the app? Can I use Krugo on my computer?
                  <i class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse8" class="panel-collapse collapse in">
              <div class="panel-body">
                You can use the Krugo website to find out what’s happening wherever you are. Head to the homepage and
                pick
                a city. We’ll show you the best concerts, sports, and shows happening tonight, tomorrow, or any dates
                you
                pick. Plus, we help you find deals on tickets! <br><br>

                From there, Krugo can do so much more for you but you’ll have to download the app for the full
                experience.
                It’s free to download for iOS and Android devices. <a href="http://get.krugoapp.com">Click
                  here</a> to download the app today!
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse9">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse9">
                  What should I do if I have questions about a ticket purchase or accommodations booking? <i
                    class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse9" class="panel-collapse collapse in">
              <div class="panel-body">Have questions about tickets purchased through Vivid Seats? Get in touch with
                their
                <a href="https://www.vividseats.com/vivid-seats-customer-service.html"> customer service department</a>
                <br><br>

                Have questions about an accommodation booking through Booking.com? Get in touch with their <a
                  href="https://secure.booking.com/content/cs.en-gb.html?label=opensearch-plugin;sid=f88c1363575ffb0192694c875de97d4d;srpvid=b6e982bee29f0012&">
                  customer support squad</a> <br><br>

                Still looking for answers? Drop us a line at <a href="mailto:hello@krugoapp.com">hello@krugoapp.com</a>
                and we’ll get back to you right away!
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse10">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse10">
                  Are you planning to add anything else to Krugo? <i
                    class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a> </div>
            </div>
            <div id="collapse10" class="panel-collapse collapse in">
              <div class="panel-body">100%, yes. If Krugo is going to be the digital HQ for your social life, we know
                that
                you’re going to need access to all sorts of important stuff like movie listings and information on the
                best restaurants. Keep your eyes and ears open - we’re adding new stuff all the time. <br><br>

                Interested in partnering up with Krugo to help us add more content and bookable offerings to the
                platform?
                We’re into that. Find out more <a href="https://krugoapp.com/#/partnership">here. </a> <br><br>

                Is there something missing from your Krugo experience? We’d love to hear your ideas! Unless you plan to
                build your own app. In that case… goodbye forever.
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse11">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse11">
                  How does the app use my location data? <i
                    class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse11" class="panel-collapse collapse in">
              <div class="panel-body">Krugo uses your location data to show you the things that we think will be most
                relevant to you based on where you are. <br><br>

                If you just arrived in a new city, all you need to do is open the app, and we’ll show you all of the
                events that are scheduled in the area that week. And if you’re at home… well… it does pretty much the
                same
                thing. And that, my friends, is how we use your location data to your benefit.
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse12">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse12">
                  Can I use Krugo anywhere in the world? <i
                    class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse12" class="panel-collapse collapse in">
              <div class="panel-body">Right now, Krugo works best when used within North America. Primarily, we
                aggregate
                events from across Canada and the US. That said, we’re adding more cities, more events and more content
                all the time so we can spread Krugo to users around the globe.
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse13">
              <div class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse13">
                  How secure is my information on Krugo? <i
                    class="fa fa-angle-down rotate-reset float-right question-arrow text-align-center"></i></a>
              </div>
            </div>
            <div id="collapse13" class="panel-collapse collapse in">
              <div class="panel-body">You’re in good hands with Krugo. We use Amazon Web Services (AWS) as our cloud
                hosting provider. They’re the real deal. All Krugo data is stored in highly secure AWS data
                centers.<br><br>

                If you would like more information, please email us at <a
                  href="mailto:hello@krugoapp.com">hello@krugoapp.com</a> - we’ve got some really smart
                people that know all of the specific details.
              </div>
            </div>
            <hr>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="contact-form">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3 col-12 contactContainer">
        <p class="titleText">Contact</p>
        <p class="subText">Can’t find what you’re looking for, have questions about Krugo, or want to become a part of
          the team? Let us know below and we will get back to you!</p>
      </div>
      <div class="col-lg-9 col-12 contactContainer">
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="needs-validation" novalidate>
          <div class="row emailRow">
            <div class="col-12 col-lg-6">
              <label class="formLabel">Full Name</label>
              <input formControlName="name" required type="text" class="form-control" name="name" id="name"
                placeholder="Enter Full Name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
            </div>
            <div class="col-12 col-lg-6">
              <label class="formLabel">Email</label>
              <input formControlName="email" type="email" class="form-control" name="email" id="emailAddress"
                aria-describedby="emailHelp" placeholder="hello@email.com"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
            </div>
          </div>
          <div class="form-group">
            <textarea formControlName="message" required class="form-control" name="message" id="message"
              placeholder="Enter a message..." rows="3"
              [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
          </div>
          <button type="submit" class="btn btn-md btn-primary" [disabled]="submitting || success">
            <span *ngIf="submitting">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
            </span>
            <span *ngIf="success">Thanks!</span>
            <span *ngIf="!success">Submit</span>
          </button>
        </form>
      </div>
    </div>
  </div>
</section>