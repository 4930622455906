import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { BlogPost } from 'src/models/BlogPost';
import { ContactForm } from 'src/models/ContactForm';
import { IgPost } from 'src/models/IgPost';


@Injectable()
export class ApiService {

  protected http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  protected createHeaders(): object {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return {
      headers: headers
    };
  }

  public sendDownloadLink(pNumber: string): Observable<any> {
    pNumber = '+1' + pNumber;
    const url = `${environment.apiBaseUrl}/text-download-link?phoneNumber=${pNumber}`;
    const headers = this.createHeaders();
    return this.http.get<any>(url, headers);
  }

  public sendContactEmail(contactForm: ContactForm): Observable<any> {
    const url = `${environment.apiBaseUrl}/send-contact-email`;
    const headers = this.createHeaders();
    return this.http.post<any>(url, contactForm, headers);
  }

  public getMediumPosts(): Observable<BlogPost[]> {
    const url = `${environment.apiBaseUrl}/get-medium-posts`;
    const headers = this.createHeaders();
    return this.http.get<BlogPost[]>(url, headers);
  }

  public getIgPosts(): Observable<IgPost[]> {
    const url = `${environment.apiBaseUrl}/get-instagram-posts?size=l`;
    const headers = this.createHeaders();
    return this.http.get<IgPost[]>(url, headers);
  }

  public buyPasses() {
    // const url = `${environment.buyPasses}`;
    window.open('https://www.showpass.com/o/krugo/');
  }
}
