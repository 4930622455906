<app-nav></app-nav>
<section class="splash">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <p class="splashTitle">KrugoFest</p>
        <p class="splashSub">Regina's Summer Rooftop Concert Series</p>
        <img class="img-fluid" src="../../assets/images/krugofest/KrugoFest.png">
      </div>
    </div>
  </div>
  <nav class="navbar fest-nav">
    <ul class="navbar-nav scroll">
      <li><a class="nav-link" [routerLink]="'plan'" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact:true}">Plan</a></li>
      <li><a class="nav-link" [routerLink]="'passes'" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact:true}">Packages</a></li>
      <li><a class="nav-link" [routerLink]="'music'" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact:true}">Music</a></li>
      <li><a class="nav-link" [routerLink]="'safety'" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact:true}">Safety</a></li>
      <li><a class="nav-link" [routerLink]="'faq'" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact:true}">FAQ</a></li>
    </ul>
  </nav>
  <hr class="navUnderline">
  <router-outlet></router-outlet>

  <section class="foodBank mt-5">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-6 align-self-center">
          <img class="img-fluid mb-5" src="../../assets/images/krugofest/foodBank.svg">
          <p class="foodBankTitle">In support of the Regina Food Bank</p>
          <p class="foodBankDetails">We are using KrugoFest to help raise money for those who need it most. With your
            purchase of a KrugoFest package, a portion of the money will go to Regina's Food Bank.</p>
            <button class="btn-primary" (click)="learnMore()">Learn More</button>
        </div>
        <div class="col-12 col-md-6 mb-4">
          <img class="img-fluid img" src="../../assets/images/krugofest/FoodBank.jpg">
        </div>
      </div>
    </div>
    <hr class="bottomDivider">
  </section>

  <section class="sponsorBar">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4 col-6 logo mb-3">
          <img class="img-fluid" src="../../assets/images/krugofest/sponsors/Jump.png">
        </div>
        <div class="col-md-4 col-6 logo mb-3">
          <img class="img-fluid" src="../../assets/images/krugofest/sponsors/Krugo.png">
        </div>
        <div class="col-md-4 col-6 logo mb-3">
          <img class="img-fluid" src="../../assets/images/krugofest/sponsors/Leos.png">
        </div>
        <div class="col-md-4 col-6 logo mb-3">
          <img class="img-fluid" src="../../assets/images/krugofest/sponsors/CAA.png">
        </div>
        <div class="col-md-4 col-6 logo mb-3">
          <img class="img-fluid" src="../../assets/images/krugofest/sponsors/RAWK.png">
        </div>
        <div class="col-md-4 col-6 logo mb-3">
          <img class="img-fluid" src="../../assets/images/krugofest/sponsors/Capitol.png">
        </div>
        <div class="col-md-4 col-6 logo mb-3">
          <img class="img-fluid" src="../../assets/images/krugofest/sponsors/TourismRegina.png">
        </div>
        <div class="col-md-4 col-6 logo mb-3">
          <img class="img-fluid" src="../../assets/images/krugofest/sponsors/Conexus.png">
        </div>
        <div class="col-md-4 col-6 logo mb-3">
          <img class="img-fluid" src="../../assets/images/krugofest/sponsors/Path.png">
        </div>
        <div class="col-md-4 col-6 logo mb-3">
          <img class="img-fluid" src="../../assets/images/krugofest/sponsors/IBAS.png">
        </div>
        <div class="col-md-4 col-6 logo mb-3">
          <img class="img-fluid" src="../../assets/images/krugofest/sponsors/DoubleTree.png">
        </div>
        <div class="col-md-4 col-6 logo mb-3">
          <img class="img-fluid" src="../../assets/images/krugofest/sponsors/GreyStone.png">
        </div>
      </div>
    </div>
    <hr>
  </section>
</section>