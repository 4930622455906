export class City {
  state: string;
  id: string;
  city: string;
  countryCode: string;
  lat: number;
  lng: number;
  timezone: string;
  searchCount: number;
  lastUpdated: number;

  static getCityStateString(city: City): string {
    if (city.state.length > 0) {
      return city.city + ', ' + city.state;
    } else if (city.countryCode.length > 0) {
      return city.city + ', ' + city.countryCode;
    }

    return city.city;
  }
}
