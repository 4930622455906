import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/services/api.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import * as $ from 'jquery';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  contactForm: FormGroup;
  submitted: boolean;
  submitting: boolean;
  success: boolean;


  constructor(private formBuilder: FormBuilder, private apiService: ApiService) { }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required],
      message: ['', Validators.required],
    });

    $('.panel-title').on('click', function (event) {
      $(this).find('.question-arrow').toggleClass('rotate');
      $(this).find('.question-arrow').toggleClass('rotate-reset');
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.contactForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }
    const contactForm = this.contactForm.value;
    contactForm.contactType = 'contactform';
    contactForm.message = 'Full Name: ' + contactForm.name + ' ' + contactForm.message;
    this.submitting = true;
    this.apiService.sendContactEmail(contactForm)
      .subscribe(_ => {
        this.submitting = false;
        this.success = true;
      }, (err) => {
        console.log(err);
      });
  }
}
