<app-nav></app-nav>
<section class="splash">
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <h1 class="headerTitle">Start planning <br> with <span class="highlight">krugo.</span></h1>
      </div>
    </div>
  </div>
</section>

<section class="carousel">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="swiper-container" [swiper]="swiperConfig">
          <div class="swiper-wrapper">
            <div class="swiper-slide" *ngFor="let slide of slides">
              <img class="img-fluid slideImage" src="{{slide.src}}">
              <div class="carouselInfo">
                <p class="photoTitle">{{slide.description}}</p>
                <p class="photographer">{{slide.by}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="horizontalLine">
  </div>
</section>

<section class="features">
  <div class="container-fluid">
    <div class="row buttonRow">
      <div class="col-lg-2 col-4 featureButton" id="ExperiencesButton" (click)="categoryButtonClicked('Experiences')">
        <div class="center">
          <img class="img-fluid" class="featureIcon" id="ExperiencesIcon"
            src="../../assets/images/explore2/experiences.svg">
          <p class="featureButtonText">Experiences</p>
        </div>
      </div>
      <div class="col-lg-2 col-4 featureButton" id="PlansButton" (click)="categoryButtonClicked('Plans')">
        <div class="center">
          <img class="img-fluid" class="featureIcon" id="PlansIcon" src="../../assets/images/explore2/plans.svg">
          <p class="featureButtonText">Plans</p>
        </div>
      </div>
      <div class="col-lg-2 col-4 featureButton" id="GroupsButton" (click)="categoryButtonClicked('Groups')">
        <div class="center">
          <img class="img-fluid" class="featureIcon" id="GroupsIcon" src="../../assets/images/explore2/groups.svg">
          <p class="featureButtonText">Groups</p>
        </div>
      </div>
      <div class="col-lg-2 col-4 featureButton" id="StaysButton" (click)="categoryButtonClicked('Stays')">
        <div class="center">
          <img class="img-fluid" class="featureIcon" id="StaysIcon" src="../../assets/images/explore2/stays.svg">
          <p class="featureButtonText">Stays</p>
        </div>
      </div>
      <div class="col-lg-2 col-4 featureButton" id="GuidesButton" (click)="categoryButtonClicked('Guides')">
        <div class="center">
          <img class="img-fluid" class="featureIcon" id="GuidesIcon" src="../../assets/images/explore2/guides.svg">
          <p class="featureButtonText">Guides</p>
        </div>
      </div>
      <div class="col-lg-2 col-4 featureButton" id="SuggestionsButton" (click)="categoryButtonClicked('Suggestions')">
        <div class="center">
          <img class="img-fluid" class="featureIcon" id="SuggestionsIcon"
            src="../../assets/images/explore2/suggestions.svg">
          <p class="featureButtonText">Suggestions</p>
        </div>
      </div>
    </div>
    <div class="row featureDetails">
      <div class="col-12 col-md-6 align-self-center">
        <p class="featureTitle">{{selectedSection.title}}</p>
        <p class="featureSubtitle">{{selectedSection.subTitle}}</p>
        <p class="detailsText">{{selectedSection.details}}</p>
        <button *ngIf="mobile" id="featureButton" class="btn-primary"
          (click)="deepLinkClicked()">{{selectedSection.buttonTitle}}</button>
        <app-sendlink *ngIf="!mobile" label="{{selectedSection.buttonTitle}}"></app-sendlink>
      </div>
      <div class="col-12 col-md-6">
        <img class="img-fluid" src="{{selectedSection.src}}">
      </div>
    </div>
    <hr class="horizontalLine">
  </div>
</section>

<section class="app">
  <div class="container-fluid">
    <div class="row appContainer">
      <div class="col-md-6 col-12">
        <img class="img-fluid appGraphic" src="../../assets/images/explore2/appGraphic.png">
      </div>
      <div
        class="col-12 col-lg-5 col-md-6 align-self-center text-left text-sm-left text-md-left text-lg-left text-xl-left">
        <h3 class="mobile-header-text">It's all in the app!</h3>
        <h6 class="mobile-header-subtitle">We’ll send you a link to download the free Krugo app for your iOS or Android
          device.</h6>
        <div class="dl-buttons">
          <app-sendlink label="Send app to phone"></app-sendlink>
          <a href="https://itunes.apple.com/us/app/krugo/id1393726426?mt=8"><img src="./assets/images/icons/apple.png"
              class="img-fluid apple-android" alt="Apple and Android Logos"> </a>
          <img src="./assets/images/icons/plus.png" class="img-fluid plus-spacing" alt="Apple and Android Logos">
          <a href="https://play.google.com/store/apps/details?id=com.krugo.krugo"><img
              src="./assets/images/icons/android.png" class="img-fluid plus-spacing" alt="Apple and Android Logos"></a>
        </div>
      </div>
    </div>
    <hr class="horizontalLine">
  </div>
</section>

<section class="partners">
  <div class="container-fluid">
    <div class="row partnerTitle">
      <div class="col-md-5 col-12">
        <h1 class="partnerSolutions">Partner Solutions</h1>
        <p class="detailsText">A fully configurable and customizable service to help your business deliver high-quality
          content and bookable offerings through your digital products.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-3">
        <img class="img-fluid partnerImg" src="../../assets/images/explore2/destination.svg">
        <h5 class="partnerHeader">Destination Content</h5>
        <p class="detailsText">Be there during every step of the travel planning journey by helping your guests to
          discover the best of what every destination has to offer.</p>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <img class="img-fluid partnerImg" src="../../assets/images/explore2/bookable.svg">
        <h5 class="partnerHeader">Bookable Offerings</h5>
        <p class="detailsText">Pave the path-to-purchase for bookable offerings - things like event tickets, tours &
          activities and restaurant reservations.</p>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <img class="img-fluid partnerImg" src="../../assets/images/explore2/revenue.svg">
        <h5 class="partnerHeader">Advertising Revenue</h5>
        <p class="detailsText">Drive ancillary revenue through a highly-targeted advertising strategy built on the
          valuable data you already have.</p>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <img class="img-fluid partnerImg" src="../../assets/images/explore2/engagement.svg">
        <h5 class="partnerHeader">User Engagement</h5>
        <p class="detailsText">Use insights about your guests to increase engagement of digital products en-route and
          between journeys.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-4">
        <div class="text-center">
          <button id="learnMoreButton" class="btn-primary"><a [routerLink]="'/partners'">Learn More</a></button>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #deeplinkModal>
  <div id="deepLinkModal">
    <div class="modal-body">
      <div class="container-fluid deepLinkContainer">
        <h5>See Krugo in...</h5>
        <hr class="deepLinkLine">
        <div class="row deepLinkRow">
          <div class="col-3">
            <img src="./assets/images/icons/appicon.png" class="deepLinkLogo">
          </div>
          <div class="col-4 align-self-center deeplinkText">
            Krugo App
          </div>
          <div class="col-2 offset-2 align-self-center">
            <button class="deeplinkModalButton" (click)="deepLinkClicked(); deeplinkModalRef.hide()">Open</button>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <img src="./assets/images/icons/interneticon.png" class="deepLinkLogo">
          </div>
          <div class="col-4 align-self-center deeplinkText">
            Browser
          </div>
          <div class="col-2 offset-2 align-self-center">
            <button target="_blank" class="deeplinkHollowModalButton"
              (click)="deeplinkModalRef.hide()">Continue</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>