import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ExploreService } from '../../services/explore.service';
import { KrugoImageService } from '../../services/image.service';
import { ImageUrl } from '../../models/ImageMetadata';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal'; //
import { CookieService } from 'ngx-cookie-service'; //
import { Observable } from 'rxjs/internal/Observable';
import { BsDaterangepickerDirective, isSameDay, TypeaheadContainerComponent, TypeaheadDirective, TypeaheadMatch } from 'ngx-bootstrap';
import { catchError, map, switchMap } from 'rxjs/operators';
import 'rxjs-compat/add/operator/map';
import { of, Subscription } from 'rxjs';
import { TypeaheadResult } from '../../models/TypeaheadResult';
import { City } from '../../models/City';
import { DropdownDirective } from 'angular-custom-dropdown';
import { SwiperDirective } from 'ngx-swiper-wrapper';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs-compat/add/operator/filter';
import MetricsHelper from '../utils/MetricsHelper';
import DateHelper from '../utils/DateHelper';
import { EventCardViewModel } from './EventCardViewModel';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';

let component;

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss'],
  animations: [
    trigger('resultsAnimation', [
      state('close', style({
        opacity: 0,
        height: 0,
      })),
      state('open', style({
        opacity: 1,
        height: '*',
      })),
      transition('close <=> open', animate(500)),
    ])],
})

export class ExploreComponent implements OnInit, AfterViewInit {
  defaultGrayImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM88x8AAp0BzdNtlUkAAAAASUVORK5CYII=';
  modalRef: BsModalRef;
  lat = 40.712776;
  lng = -74.005974;
  deeplinkModalRef: BsModalRef;
  images = new Map<string, ImageUrl>();
  todayImages = new Map<string, ImageUrl>();
  upcomingImages = new Map<string, ImageUrl>();
  userLat = '';
  userLng = '';

  todayEvents: EventCardViewModel[] = [];
  upcomingEvents: EventCardViewModel[] = [];

  multipleEvents = [];
  tempImages = [];
  typeaheadDataSource: Observable<any>;
  typeaheadText = 'New York City, NY';
  headerText = 'Discover things in';
  typeaheadLoading: boolean;
  typeaheadLoadAll = false;
  typeaheadTitleText = '';
  selectedCity: City = null;
  selectedCityImageUrl = '/assets/images/explore/newyork.jpg';
  selectedDates = [new Date(), new Date()];
  searchDateText = '';
  searchYear;
  searchUpcomingTitle = 'New York City Events';
  searchUpcomingDateRangeText = '';
  loadingTodaySection = false;
  searchingTodaySection = false;
  loadingUpcomingSection = false;
  todaySectionSubscription: Subscription = null;
  upcomingSectionSubscription: Subscription = null;
  searchInit = 0;
  imageURL;
  noTodayEvents = false;
  noEventsText = 'Looks like there are no events in ' + this.typeaheadText + '.';
  noTodayEventsText = 'Looks like there are no events in ' + this.typeaheadText + ' today';


  limitedShowAmount = 6;
  showAll = false;
  vividLink = '';

  tempEventArray = [];

  hidePrev = true;
  hideNext = false;

  minDate = this.exploreService.getMinDate();

  TodayConfig: any = {
    initialSlide: 0,
    centeredSlides: false,
    grabCursor: true,
    spaceBetween: 15,
    slidesPerView: 4.1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
      hide: false,
    },
    preloadImages: true,
    breakpoints: {
      // when window width is <= 320px
      363: {
        slidesPerView: 1.1,
        spaceBetween: 10,
      },
      // when window width is <= 480px
      480: {
        slidesPerView: 1.3,
        spaceBetween: 20
      },
      571: {
        slidesPerView: 1.75,
        spaceBetween: 20
      },
      689: {
        slidesPerView: 2.1,
        spaceBetween: 20
      },
      // when window width is <= 841px
      841: {
        slidesPerView: 2.5,
        spaceBetween: 25
      },
      1180: {
        slidesPerView: 3.25,
        spaceBetween: 25
      },
    }
  };

  @ViewChild(TypeaheadDirective) _typeahead: TypeaheadDirective;

  public get container(): TypeaheadContainerComponent {
    return this._typeahead._container;
  }

  @ViewChild('typeaheadCitySearch') typeaheadElement: ElementRef;
  @ViewChild(DropdownDirective) dropDownElement: DropdownDirective;
  @ViewChild(BsDaterangepickerDirective) calendarPicker: BsDaterangepickerDirective;
  @ViewChild('deeplinkModal') deeplinkModal: TemplateRef<any>;
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  @ViewChild('myCustomListTemplate') myCustomListTemplate: TemplateRef<any>;
  @ViewChild('myCustomItemTemplate') myCustomItemTemplate: TemplateRef<any>;

  @ViewChild('multipleModal')
  private multipleModal: TemplateRef<any>;

  constructor(private exploreService: ExploreService, private imageService: KrugoImageService, private _sanitizer: DomSanitizer,
    private modalService: BsModalService, private cookieService: CookieService, private route: ActivatedRoute,
    private router: Router, private breakpointObserver: BreakpointObserver, private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.route
      .queryParams
      .subscribe(params => {
        this.populateSearchFromParams(params);
      });

    this.observeBreakpoints();
    this.observeTypeahead();

    if (this.searchDateText.length === 0) {
      this.datePickerSelectedOption('today', true);
    }
    // this.performSearch();

    component = this;
    this.exploreService.eventSet = false;

    if (this.exploreService.todayEvents.length !== 0) {
      this.loadTodaySearchResults(this.exploreService.getTodayEvents());
      this.searchingTodaySection = true;
      this.typeaheadText = this.exploreService.getCityText();
      this.selectedCityImageUrl = this.exploreService.getBannerURL();
      this.searchInit = 1;
    }
    if (this.exploreService.upcomingEvents.length !== 0) {
      this.loadUpcomingSearchResults(this.exploreService.getUpcomingEvents());
      this.searchUpcomingDateRangeText = this.exploreService.getUpcomingDateRange();
      this.typeaheadText = this.exploreService.getCityText();
      this.selectedCityImageUrl = this.exploreService.getBannerURL();
      this.searchInit = 1;
      this.searchUpcomingTitle = this.exploreService.getCityText().substring(0, this.exploreService.getCityText().length - 4) + ' Events';
    }

    if ((window.innerWidth < 500) && !this.exploreService.seenModal) {
      this.openDeeplinkModal(this.deeplinkModal);
      this.exploreService.seenModal = !this.exploreService.seenModal;
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  observeBreakpoints() {
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe((s: BreakpointState) => {
        for (const breakpointsKey in s.breakpoints) {
          if (s.breakpoints[breakpointsKey]) {
            if (breakpointsKey === Breakpoints.Large || breakpointsKey === Breakpoints.XLarge) {
              this.limitedShowAmount = 8;
            } else {
              this.limitedShowAmount = 6;
            }
          }
        }
      });
  }

  observeTypeahead() {
    this.typeaheadDataSource = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.typeaheadText);
    }).pipe(switchMap((token: string) => this.typeaheadSearch(token)));
  }

  populateSearchFromParams(params) {
    if (params['lat'] && params['lng'] && params['cityId'] && params['city'] && params['state']) {
      this.selectedCity = new City();
      this.selectedCity.state = params['state'];
      this.selectedCity.city = params['city'];
      this.selectedCity.id = params['cityId'];
      this.selectedCity.lat = params['lat'];
      this.selectedCity.lng = params['lng'];
      this.changeBannerBackground();
      if (this.typeaheadText === 'Near You') {
        this.typeaheadText = 'Near You';
      } else {
        this.typeaheadText = City.getCityStateString(this.selectedCity);
      }
      this.setSelectedCity(this.selectedCity);
    }
    if (params['dates']) {
      const dates = params['dates'];
      this.selectedDates = [new Date(dates[0]), new Date(dates[1])];
      if (this.searchDateText.length === 0) {
        this.searchDateText = DateHelper.getTextForDateRange(this.selectedDates);
      }
    }
  }

  populateParamsFromSearch() {
    const urlTree = this.router.parseUrl(this.router.url);
    if (this.selectedCity) {
      urlTree.queryParams['city'] = this.selectedCity.city;
      urlTree.queryParams['cityId'] = this.selectedCity.id;
      urlTree.queryParams['state'] = this.selectedCity.state;
      urlTree.queryParams['lat'] = this.lat;
      urlTree.queryParams['lng'] = this.lng;
      urlTree.queryParams['dates'] = this.selectedDates;
      this.router.navigateByUrl(urlTree).then(() => {
        // this block is required to silence a warning
      });
    }
  }

  getTodayImages(todayEventViewModels: EventCardViewModel[]) {
    if (todayEventViewModels.length === 0) {
      return;
    }
    let imageIds = todayEventViewModels.map((e) => e.events[0].primaryPerformer.primaryCategoryId);
    const duplicates = imageIds.filter((e, i, a) => a.indexOf(e) !== i);
    imageIds = imageIds.concat(todayEventViewModels.map((e) => e.events[0].primaryPerformer.id));
    imageIds = imageIds.concat(todayEventViewModels.map(e => e.events[0].eventTypeId));
    const distinctIds = imageIds.filter((v, i) => imageIds.indexOf(v) === i && v != null);
    this.imageService.getTodayImagesForIds(duplicates).subscribe((images) => {
      images.forEach((image) => {
        this.tempImages.push(image);
        this.todayImages[image.id] = image.urls.find((u) => u.size === 'medium').url;
      });
      this.todayEvents = todayEventViewModels;
    }, (error) => {
      console.log(error);
    });
    this.imageService.getTodayImagesForIds(distinctIds).subscribe((images) => {
      images.forEach((image) => {
        this.tempImages.push(image);
      });
      this.filterArray(this.todayEvents);
    });
    setTimeout(() => {
      this.loadingTodaySection = false;
    }, 1000);
  }

  filterArray(eventArray) {
    const hash = [];
    for (let i = 0; i < this.tempImages.length; i++) {
      if (!hash[this.tempImages[i]['id']]) { hash[this.tempImages[i]['id']] = []; }
      hash[this.tempImages[i]['id']].push(this.tempImages[i]);
    }
    this.tempImages = hash;
    this.getRandomImage(eventArray);
  }

  getRandomImage(eventArray) {
    eventArray.forEach(events => {
      const event = events.events[0];
      const imageArray = this.tempImages[event.primaryPerformer.id] ||
        (this.tempImages[event.primaryPerformer.primaryCategoryId]) ||
        (this.tempImages[event.eventTypeId]);
      const randomImage = imageArray[Math.floor(Math.random() * imageArray.length)];
      const url = randomImage.urls.find((u) => u.size === 'medium').url;
      events.imageUrl = url;
    });
  }

  getUpcomingImages(upcomingEventViewModels: EventCardViewModel[]) {
    if (upcomingEventViewModels.length === 0) {
      return;
    }

    let imageIds = upcomingEventViewModels.map((e) => e.events[0].primaryPerformer.primaryCategoryId);
    const duplicates = imageIds.filter((e, i, a) => a.indexOf(e) !== i);
    imageIds = imageIds.concat(upcomingEventViewModels.map((e) => e.events[0].primaryPerformer.id));
    imageIds = imageIds.concat(upcomingEventViewModels.map(e => e.events[0].eventTypeId));
    const distinctIds = imageIds.filter((v, i) => imageIds.indexOf(v) === i && v != null);
    this.imageService.getUpcomingImagesForIds(duplicates).subscribe((images) => {
      images.forEach((image) => {
        this.tempImages.push(image);
        this.upcomingImages[image.id] = image.urls.find((u) => u.size === 'medium').url;
      });
      this.upcomingEvents = upcomingEventViewModels;
    }, (error) => {
      console.log(error);
    });
    this.imageService.getUpcomingImagesForIds(distinctIds).subscribe((images) => {
      images.forEach((image) => {
        this.tempImages.push(image);
      });
      this.filterArray(this.upcomingEvents);
    });
    setTimeout(() => {
      this.loadingUpcomingSection = false;
    }, 1000);
  }

  searchTapped() {
    MetricsHelper.logEvent('Explore_Search_Tapped');
    this.performSearch();
    if (this.typeaheadText !== 'Near You') {
      this.headerText = 'Discover things in';
    }
    if (this.selectedCity) {
      this.changeBannerBackground();
    }
  }

  changeBannerBackground() {
    this.imageService.getTodayImagesForIds([this.selectedCity.id]).subscribe((images) => {
      if (images.length > 0) {
        const image = images[0];
        this.selectedCityImageUrl = image.urls.find((u) => u.size === 'large').url;
      }
    }, (error) => {
      console.log(error);
    });
  }

  performSearch() {
    this.searchInit = 1;
    const today = new Date();
    this.todayEvents = [];
    this.upcomingEvents = [];
    this.noTodayEvents = false;
    this.tempEventArray = [];

    if (this.typeaheadText === 'Near You') {
      // tslint:disable-next-line: max-line-length
      this.noEventsText = 'Looks like there are no events ' + this.typeaheadText + '. Try another search to find awesome events going on!';
    } else {
      // tslint:disable-next-line: max-line-length
      this.noEventsText = 'Looks like there are no events in ' + this.typeaheadText + '. Try another search to find awesome events going on!';
    }

    if (this.typeaheadText === 'Near You') {
      // tslint:disable-next-line: max-line-length
      this.noTodayEventsText = 'Looks like there are no events ' + this.typeaheadText + ' today. Try another search to find awesome events going on!';
    } else {
      // tslint:disable-next-line: max-line-length
      this.noTodayEventsText = 'Looks like there are no events in ' + this.typeaheadText + ' today. Try another search to find awesome events going on!';
    }

    if (this.todaySectionSubscription) {
      this.todaySectionSubscription.unsubscribe();
    }
    if (this.upcomingSectionSubscription) {
      this.upcomingSectionSubscription.unsubscribe();
    }

    // set upcoming section title
    if (this.selectedCity) {
      this.searchUpcomingTitle = this.selectedCity.city + ' Events';
    }

    if (isSameDay(this.selectedDates[0], today)) {
      this.searchingTodaySection = true;
      // search for today and upcoming separately
      this.loadingTodaySection = true;
      this.todaySectionSubscription = this.exploreService.getNearbyEvents(this.lat, this.lng, [today, today]).subscribe(
        (nearbyEvents) => {
          if (nearbyEvents != null) {
            this.updateTicketPrices(nearbyEvents);
          } else {
            const viewModels = EventCardViewModel.generateViewModelsForEvents(nearbyEvents);
            this.setTodaySearchResults(viewModels);
            this.noTodayEvents = true;
            this.loadingTodaySection = false;
            this.loadingUpcomingSection = false;
          }
        }, (error) => {
          console.log(error);
          this.loadingTodaySection = false;
        }
      );

      if (!isSameDay(this.selectedDates[0], this.selectedDates[1])) {
        const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
        this.loadingUpcomingSection = true;
        this.searchUpcomingDateRangeText = DateHelper.getTextForDateRange([tomorrow, this.selectedDates[1]]).toUpperCase();
        this.upcomingSectionSubscription = this.exploreService
          .getNearbyEvents(this.lat, this.lng, [tomorrow, this.selectedDates[1]])
          .subscribe(
            (nearbyEvents) => {
              this.tempEventArray.push(...nearbyEvents);
              if (nearbyEvents != null) {
                const viewModels = EventCardViewModel.generateViewModelsForEvents(nearbyEvents);
                this.setUpcomingSearchResults(viewModels.slice(0, 40));
              } else {
                const viewModels = EventCardViewModel.generateViewModelsForEvents(nearbyEvents);
                this.setUpcomingSearchResults(viewModels.slice(0, 40));
                this.loadingTodaySection = false;
                this.loadingUpcomingSection = false;
              }
            }, (error) => {
              console.log(error);
              this.loadingUpcomingSection = false;
            }
          );
      } else {
        this.setUpcomingSearchResults([]);
      }
    } else {
      this.searchingTodaySection = false;
      // perform search as usual for upcoming
      this.loadingUpcomingSection = true;
      this.searchUpcomingDateRangeText = DateHelper.getTextForDateRange(this.selectedDates).toUpperCase();
      this.searchYear = DateHelper.checkYear(this.selectedDates);
      this.upcomingSectionSubscription = this.exploreService.getNearbyEvents(this.lat, this.lng, this.selectedDates).subscribe(
        (nearbyEvents) => {
          this.tempEventArray.push(...nearbyEvents);
          if (nearbyEvents != null) {
            const viewModels = EventCardViewModel.generateViewModelsForEvents(nearbyEvents);
            this.setUpcomingSearchResults(viewModels);
          } else {
            const viewModels = EventCardViewModel.generateViewModelsForEvents(nearbyEvents);
            this.setUpcomingSearchResults(viewModels);
            this.loadingTodaySection = false;
            this.loadingUpcomingSection = false;
          }
        }, (error) => {
          console.log(error);
          this.loadingUpcomingSection = false;
        }
      );
    }

    this.populateParamsFromSearch();
  }

  updateSwiper() {
    if (this.directiveRef) {
      this.directiveRef.update();
    }
  }

  setTodaySearchResults(eventViewModels: EventCardViewModel[]) {
    const today = new Date();
    const filteredResults = eventViewModels.filter((i) => {
      const eventDate = new Date(i.events[0].eventDateString);
      return isSameDay(eventDate, today);
    });
    this.getTodayImages(filteredResults);
    this.todayEvents = filteredResults;
    this.updateSwiper();
  }

  setUpcomingSearchResults(eventViewModels: EventCardViewModel[]) {
    const today = new Date();
    const filteredResults = eventViewModels.filter((i) => {
      const eventDate = new Date(i.events[0].eventDateString);
      return !isSameDay(eventDate, today);
    });

    this.getUpcomingImages(filteredResults);
    this.upcomingEvents = filteredResults;
    if (this.upcomingEvents.length < this.limitedShowAmount) {
      this.showAll = true;
    } else {
      this.showAll = false;
    }
    this.updateSwiper();
    this.exploreService.setUpcomingEvents(this.upcomingEvents);
    this.updateUpcomingTicketPrices(this.tempEventArray);
  }

  loadTodaySearchResults(eventViewModels: EventCardViewModel[]) {
    const today = new Date();
    const filteredResults = eventViewModels.filter((i) => {
      const eventDate = new Date(i.events[0].eventDateString);
      return isSameDay(eventDate, today);
    });

    this.todayEvents = filteredResults;
    this.updateSwiper();
  }

  loadUpcomingSearchResults(eventViewModels: EventCardViewModel[]) {
    const today = new Date();
    const filteredResults = eventViewModels.filter((i) => {
      const eventDate = new Date(i.events[0].eventDateString);
      return !isSameDay(eventDate, today);
    });

    this.upcomingEvents = filteredResults;
    if (this.upcomingEvents.length < this.limitedShowAmount) {
      this.showAll = true;
    } else {
      this.showAll = false;
    }
    this.updateSwiper();
  }

  getPlanCost(plan) {
    if (plan.minPrice > 0) {
      if (plan.minPrice % 1 === 0) {
        return '$' + plan.minPrice + '.00';
      } else {
        return '$' + plan.minPrice;
      }
    } else {
      return 'No Tickets Available';
    }
  }

  openModal(template: TemplateRef<any>) {
    const config: ModalOptions = { class: 'modal-lg' };
    this.modalRef = this.modalService.show(template, config);
  }

  openDeeplinkModal(template: TemplateRef<any>) {
    const config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false
    };
    this.deeplinkModalRef = this.modalService.show(template, config);
  }

  getMultipleTime(plan) {
    const timeStamp = plan.eventDateString;
    const momentDate = moment(timeStamp);
    return ' at ' + momentDate.format('h:mm A');
  }

  getMultipleDate(plan) {
    const timeStamp = plan.eventDateString;
    const date = new Date(timeStamp);
    return date.toLocaleString('en-US', { weekday: 'short', day: 'numeric', month: 'numeric' });
  }

  eventTapped(eventCardViewModel: EventCardViewModel) {
    const eventDate = new Date(eventCardViewModel.events[0].eventDateString);
    this.exploreService.setEventArray(this.upcomingEvents);
    this.imageURL = eventCardViewModel.imageUrl;
    if (isSameDay(eventDate, new Date())) {
      MetricsHelper.logEvent('Explore_Today_EventTapped');
    } else {
      MetricsHelper.logEvent('Explore_Upcoming_EventTapped');
    }
    if (eventCardViewModel.events.length > 1) {
      this.sortMultipleEventsbyDate(eventCardViewModel.events);
      this.multipleEvents = eventCardViewModel.events;
      this.openModal(this.multipleModal);
    } else {
      this.exploreService.setEvent(eventCardViewModel);
      this.exploreService.setTodayEvents(this.todayEvents);
      this.exploreService.setUpcomingEvents(this.upcomingEvents);
      this.exploreService.setUpcomingDateRange(this.searchUpcomingDateRangeText);
      this.exploreService.setCityText(this.typeaheadText);
      this.exploreService.setBannerURL(this.selectedCityImageUrl);
      this.router.navigate(['/events']);
    }
  }

  sortMultipleEventsbyDate(multipleEvents) {
    multipleEvents.sort((a, b) => a.eventDate - b.eventDate);
  }

  getVividLink(event) {
    this.vividLink = event.url;
  }

  hasVividCookie() {
    return this.cookieService.check('vividCookie');
  }

  setVividCookie() {
    this.cookieService.set('vividCookie', 'dismiss');
  }

  // fired every time search string is changed
  typeaheadSearch(searchString: string) {
    if (this.typeaheadLoadAll) {
      searchString = '';
      this.typeaheadTitleText = 'Popular Cities';
      this.typeaheadLoadAll = false;
    } else {
      if (searchString === '') {
        this.typeaheadTitleText = 'Popular Cities';
      } else {
        this.typeaheadTitleText = 'Results for \'' + searchString + '\'...';
      }
    }

    return this.exploreService.getTypeaheadSearch(searchString).pipe(
      catchError(() => of({})),
      map((results: TypeaheadResult) => {
        if (results.cities) {
          results.cities.forEach((c: City) => c['displayName'] = City.getCityStateString(c));
        }
        return results.cities;
      }
      ));
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadFocused() {
    if (this.typeaheadText.indexOf(',') > -1 || '' || this.typeaheadText === 'Near You') {
      this.typeaheadLoadAll = true;
      this._typeahead.show();
    } else {
      this.typeaheadLoadAll = false;
      this._typeahead.show();
    }
  }

  focusOnTypeahead() {
    setTimeout(() => {
      this.typeaheadElement.nativeElement.focus();
      this.typeaheadElement.nativeElement.select();
    });
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.setSelectedCity(e.item);
  }

  setSelectedCity(city: City) {
    this.selectedCity = city;
    if (this.selectedCity != null) {
      this.lat = this.selectedCity.lat;
      this.lng = this.selectedCity.lng;
    }
  }

  typeaheadClicked(e: any): void {
    e.target.select();
  }

  datePickerSelectedOption(option: string, ignoreLogging?: boolean) {
    if (ignoreLogging !== true) {
      MetricsHelper.logEvent('ExploreSettings_TapCity');
    }
    this.dropDownElement.close();

    switch (option) {
      case 'today':
        const monthFromNow = new Date(new Date().setDate(new Date().getDate() + 30));
        const weekFromNow = new Date(new Date().setDate(new Date().getDate() + 7));
        this.searchDateText = 'Today';
        this.selectedDates = [new Date(), weekFromNow];
        break;
      case 'tomorrow':
        this.searchDateText = 'Tomorrow';
        const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
        this.selectedDates = [tomorrow, tomorrow];
        break;
      case 'thisWeekend':
        this.searchDateText = 'This Weekend';
        this.selectedDates = DateHelper.getWeekendDates(new Date());
        break;
      case 'nextWeekend':
        this.searchDateText = 'Next Weekend';
        this.selectedDates = DateHelper.getWeekendDates(new Date(new Date().setDate(new Date().getDate() + 7)));
        break;
      case 'pickDate':
        this.calendarPicker.show();
        break;
      default:
        break;
    }
  }

  datePickerSelectedDate(dates: Date[]) {
    this.searchDateText = DateHelper.getTextForDateRange(dates);
    this.selectedDates = dates;
  }

  multipleEventClicked(event) {
    setTimeout(() => {
      const tempArray = [];
      tempArray.push(event);
      const viewModel = EventCardViewModel.generateViewModelsForEvents(tempArray);
      this.exploreService.setEvent(viewModel[0]);
      this.exploreService.setImageURL(this.imageURL);
      this.exploreService.setTodayEvents(this.todayEvents);
      this.exploreService.setUpcomingEvents(this.upcomingEvents);
      this.exploreService.setUpcomingDateRange(this.searchUpcomingDateRangeText);
      this.exploreService.setCityText(this.typeaheadText);
      this.exploreService.setBannerURL(this.selectedCityImageUrl);
      this.modalRef.hide();
      this.router.navigate(['/events']);
    }, 100);
  }

  advanceSlide() {
    this.directiveRef.nextSlide();
  }

  previousSlide() {
    this.directiveRef.prevSlide();
  }

  hideNextButton() {
    this.hideNext = true;
  }

  hidePrevButton() {
    this.hidePrev = true;
  }

  showButtons() {
    this.hideNext = false;
    this.hidePrev = false;
    if (this.directiveRef.getIndex() === 0) {
      this.hidePrev = true;
    }
  }

  getUserLocation() {
    this.typeaheadText = 'Finding your location...';
    if (!navigator.geolocation) {
      console.log('Geolocation is not supported by your browser');
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.userLat = position.coords.latitude.toString();
        this.userLng = position.coords.longitude.toString();
        // this.exploreService.getMinimumVersion().subscribe(minVersion => {
        this.exploreService.getCityfromCoords(this.userLat, this.userLng, '1').subscribe(city => {
          this.headerText = 'Discover things';
          this.typeaheadText = 'Near You';
          this.selectedCity = city;
        });
        // });
      },
        err => {
          console.log(err);
          this.typeaheadText = 'New York City, NY';
          this.selectedCity = null;
          this.lat = 40.712776;
          this.lng = -74.005974;
        });
    }
  }

  updateTicketPrices(events) {
    const timeCheck = Math.floor((Date.now() / 1000) - 1800); // timeCheck = 30 min ago
    const expiredEventIds = [];
    events.forEach(event => {
      if (event.lastUpdated < timeCheck) {
        expiredEventIds.push(event.id);
      }
    });
    if (expiredEventIds.length > 0) {
      this.exploreService.updateTicketDetails(expiredEventIds).subscribe(updatedTicketDetails => {
        updatedTicketDetails.forEach(updatedEvent => {
          const foundEvent = events.find(event => event.vividId === updatedEvent.vividEventId);
          foundEvent.minPrice = updatedEvent.minPrice;
          foundEvent.maxPrice = updatedEvent.maxPrice;
          foundEvent.ticketCount = updatedEvent.ticketCount;
          foundEvent.listingCount = updatedEvent.listingCount;
          foundEvent.lastUpdated = timeCheck + 1800;
        });
        const viewModels = EventCardViewModel.generateViewModelsForEvents(events);
        this.setTodaySearchResults(viewModels.slice(0, 40));
        // this.loadingTodaySection = false;
      }, err => {
        console.log(err);
      });
    } else {
      const viewModels = EventCardViewModel.generateViewModelsForEvents(events);
      this.setTodaySearchResults(viewModels.slice(0, 40));
      // this.loadingTodaySection = false;
    }
  }

  forkJoinArray(array) {
    return forkJoin(array);
  }

  updateUpcomingTicketPrices(events) {
    const timeCheck = Math.floor((Date.now() / 1000) - 1800); // timeCheck = 30 min ago
    const expiredEventIds = [];
    const obs = [];
    const updatedDetails = [];

    events.forEach(event => {
      if (event.lastUpdated < timeCheck) {
        expiredEventIds.push(event.id);
      }
    });
    const chunk = 150;
    const chunkedArray = [];

    for (let i = 0; i < expiredEventIds.length; i += chunk) {
      const temparray = expiredEventIds.slice(i, i + chunk);
      chunkedArray.push(temparray);
    }

    if (expiredEventIds.length > 0) {
      chunkedArray.forEach(arrayChunk => {
        obs.push(this.exploreService.updateTicketDetails(arrayChunk));
      });

      const joinedObs = this.forkJoinArray(obs);
      joinedObs.subscribe(results => {
        updatedDetails.push(...results);
        const updatedTicketDetails = updatedDetails.flat(2);
        updatedTicketDetails.forEach(updatedEvent => {
          const foundEvent = events.find(event => event.vividId === updatedEvent.vividEventId);
          foundEvent.minPrice = updatedEvent.minPrice;
          foundEvent.maxPrice = updatedEvent.maxPrice;
          foundEvent.ticketCount = updatedEvent.ticketCount;
          foundEvent.listingCount = updatedEvent.listingCount;
          foundEvent.lastUpdated = timeCheck + 1800;
        });
        const viewModels = EventCardViewModel.generateViewModelsForEvents(events);
        this.setUpcomingSearchResults(viewModels.slice(0, 40));
        // return events;
      }, err => {
        console.log(err);
      });
    }
    // else {
    //   const viewModels = EventCardViewModel.generateViewModelsForEvents(events);
    //   this.setUpcomingSearchResults(viewModels.slice(0, 40));
    // }
  }

  getMultipleModalAbbreviation(event) {
    if (event.primaryPerformer.primaryCategory === null) {
      const eventType = event.primaryPerformer.eventType;
      return eventType[0].toUpperCase() + eventType.slice(1);
    } else {
      return event.primaryPerformer.primaryCategory.abbreviation;
    }
  }

  seeWhatsHappening() {
    const element = document.getElementById('upcomingEvents');
    element.scrollIntoView();
  }

  deepLinkClicked() {
    if (navigator.userAgent.indexOf('iPhone') !== -1) {
      window.open('https://itunes.apple.com/us/app/krugo/id1393726426?mt=8');
    } else if (navigator.userAgent.indexOf('Android') !== -1) {
      window.open('https://play.google.com/store/apps/details?id=com.krugo.krugo');
    } else {
      window.open('https://www.krugoapp.com');
    }
  }

}
