import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BlogPost } from 'src/models/BlogPost';
import { IgPost } from 'src/models/IgPost';
import { ApiService } from 'src/services/api.service';


@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
  encapsulation: ViewEncapsulation.None, // allows component style to propogate to innerHTML elements
})
export class MediaComponent implements OnInit {

  posts: BlogPost[];
  error: boolean;
  igPosts: IgPost[];
  firstPost: BlogPost;
  thumbImage: string;
  thumbName: string;
  isMobile: boolean;
  oldPerson = 'tanner';
  selectedPerson: any;
  postsLoaded = false;

  people: any[] = [
    {
      src: 'https://player.vimeo.com/video/392988153', name: 'Tanner Goetz',
      // tslint:disable-next-line: max-line-length
      writeup: 'NBA, NFL, & Cheesesteaks… what else could you ask for? Tanner and the ‘Philly Boys’ did right by the City of Brotherly Love during their quick weekend trip.',
      id: 'tanner'
    },
    {
      src: 'https://player.vimeo.com/video/396018886', name: 'Andrew Hiltz',
      // tslint:disable-next-line: max-line-length
      writeup: 'If there was ever a trip that should be labelled a ‘food-vacation’, this is it. Andrew spent a week eating his way around the Big Apple and had some recommendations to share.',
      id: 'andrew'
    },
    {
      src: 'https://player.vimeo.com/video/396019041', name: 'Taylor Chapman',
      // tslint:disable-next-line: max-line-length
      writeup: 'If you go on a trip with your business partner, it\'s a business trip. Right? Taylor and Colleen strengthened their bond as business partners & future sister in-laws as they explored all that Denver had to offer.',
      id: 'taylor'
    },
    {
      src: 'https://player.vimeo.com/video/396970744', name: 'Mattea Columpsi',
      // tslint:disable-next-line: max-line-length
      writeup: 'There’s nothing like a few beers and a good movie. Plus, find out where Mattea found the best latte in Vancouver. Sometimes a really good trip doesn’t have to be fancy. ',
      id: 'mattea'
    },
    {
      src: 'https://player.vimeo.com/video/418611173', name: 'Dipo Ziwa',
      // tslint:disable-next-line: max-line-length
      writeup: 'Dipo and some friends travelled to Montreal for a weekend getaway and ended up snagging some last-minute tickets to TWO amazing events.',
      id: 'dipo'
    },
    {
      src: 'https://player.vimeo.com/video/418610087', name: 'Hannah Castle',
      // tslint:disable-next-line: max-line-length
      writeup: 'Hannah, Morgan, Talitha and nine other besties travelled to Kelowna for the ultimate bachelorette weekend filled with winery tours, pedal taverns, floaty parties, and lots of laughs.',
      id: 'hannah'
    }
  ];

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    if (window.innerWidth < 500) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.getPosts();
    // this.getIgPosts();
    this.thumbImage = 'thumbImage';
    this.thumbName = 'thumbName';
    setTimeout(() => {
      this.thumbClicked('tanner');
    }, 1);
  }

  gotoPost(post) {
    window.open(post.postUrl, '_blank');
  }

  gotoigPost(igPost) {
    window.open(igPost.url, '_blank');
  }

  getigUrl(igPost) {
    return igPost.imageUrl;
  }

  getPosts(): void {
    this.apiService.getMediumPosts()
      .subscribe(posts => {
        this.posts = posts;
        this.firstPost = posts[0];
        this.postsLoaded = true;
      }, (err) => {
        this.error = true;
        console.log(err);
      });
  }

  getIgPosts(): void {
    this.apiService.getIgPosts()
      .subscribe(igPosts => {
        this.igPosts = igPosts;
      }, (err) => {
        this.error = true;
        console.log(err);
      });
  }

  thumbClicked(person) {
    this.clearClass(this.oldPerson);
    this.oldPerson = person;
    const name = document.getElementById(person + 'Name');
    name.classList.add('thumbNameSelected');
    const image = document.getElementById(person + 'Picture');
    image.classList.add('thumbImageSelected');
    const rectangle = document.getElementById(person + 'Rectangle');
    rectangle.classList.add('yellowRectangle');
    this.selectedPerson = this.people.find(element => element.id === person);
  }

  clearClass(oldPerson) {
    const name = document.getElementById(oldPerson + 'Name');
    name.classList.remove('thumbNameSelected');
    const image = document.getElementById(oldPerson + 'Picture');
    image.classList.remove('thumbImageSelected');
    const rectangle = document.getElementById(oldPerson + 'Rectangle');
    rectangle.classList.remove('yellowRectangle');
  }

}
