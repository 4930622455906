import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('.panel-title').on('click', function (event) {
      $(this).find('.question-arrow').toggleClass('rotate');
      $(this).find('.question-arrow').toggleClass('rotate-reset');
    });
  }

}
