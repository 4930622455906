<section class="banner">
  <div class="container-fluid">
    <div class="row flipRow">
      <div class="col-12 col-md-4 align-self-center">
        <h4 class="bannerHeader">Let's team up.</h4>
        <p class="bannerDetails">Driving digital engagement, ancillary revenue and cost savings for travel partners.</p>
      </div>
      <div class="col-12 col-md-8">
        <img src="../../assets/images/partnership/ggbanner.jpg" class="img-fluid">
      </div>
    </div>
    <hr class="horizontalLine">
  </div>
</section>

<section class="partnerSolutions">
  <div class="container-fluid">
    <div class="row flipRow">
      <div class="col-12 col-md-6 align-self-center">
        <h4 class="headerText">Krugo Partner Solutions</h4>
        <p class="detailsText">At Krugo, we believe that leading travel brands are focused on the experiences that truly
          motivate people to travel. <br><br> And that’s where we come in. <br><br> We offer a product that allows our
          partners to deliver
          live event schedules, restaurant recommendations and tips on nightlife and other things to do. This content
          can be wrapped in a virtually unlimited variety of commissionable offerings and presents a huge opportunity to
          drive ancillary sales revenue. <br><br> Interested in providing this type of experience to your valued
          customers as
          part of your digital product strategy?</p>
      </div>
      <div class="col-12 col-md-6 partnerImage align-self-center">
        <img src="../../assets/images/partnership/partnerSolutions.png" class="img-fluid">
      </div>
    </div>
    <hr class="horizontalLine">
  </div>
</section>

<section class="video">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="video-container">
          <iframe src="https://player.vimeo.com/video/403769898" frameborder="0" allow="autoplay; fullscreen"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <hr class="horizontalLine">
  </div>
</section>

<section class="implementation">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6 align-self-center">
        <img src="../../assets/images/partnership/implementation.png" class="img-fluid">
      </div>
      <div class="col-12 col-md-6 align-self-center">
        <h4 class="headerText">Turn-Key Product Implementation</h4>
        <p class="detailsText">Engage with customers right from their first thought about a potential trip by suggesting
          an experience worth travelling for. <br><br> From there, surface relevant content through
          the planning process and even as the trip is happening by offering suggestions for things like top-rated
          restaurants or last-minute deals on event tickets. <br><br> Want to see what partnering with Krugo might look
          like for
          your company? We’d love to show you the experience you can deliver to your customers with a little help from
          us.</p>
      </div>
    </div>
    <hr class="horizontalLine">
  </div>
</section>

<section class="growth">
  <div class="container-fluid">
    <h4 class="headerText">Drive Business Growth</h4>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-3">
        <img src="../../assets/images/partnership/expand.svg" id="growthIcon" class="img-fluid">
        <h5 class="growthHeader">Expand Ancillary Revenue</h5>
        <p class="detailsText">Leverage your existing digital products to drive ancillary revenue growth through
          commissionable integrations, advertising revenue and more.</p>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <img src="../../assets/images/partnership/discover.svg" id="growthIcon" class="img-fluid">
        <h5 class="growthHeader">Discover Data-driven Insights</h5>
        <p class="detailsText">Utilize millions of new data points to provide loyal customers with a more curated
          experience at every step of the lifecycle. Tap into the data to better understand customer booking behaviour.
        </p>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <img src="../../assets/images/partnership/increase.svg" id="growthIcon" class="img-fluid">
        <h5 class="growthHeader">Increase Digital Product Engagement</h5>
        <p class="detailsText">Add value to the traveler experience and keep customers engaged before, during and after
          their trip. Increase retention and engagement metrics for existing digital products.
        </p>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <img src="../../assets/images/partnership/address.svg" id="growthIcon" class="img-fluid">
        <h5 class="growthHeader">Address Sustainability Targets</h5>
        <p class="detailsText">Support commitments to address climate change and advance waste reduction. Transition to
          a digital content strategy and scale back on the paper and plastic used in printed collateral.
        </p>
      </div>
    </div>
    <hr class="horizontalLine">
  </div>
</section>

<section class="travel">
  <div class="container-fluid">
    <h4 class="headerText">Built for Travel</h4>
    <div class="row">
      <div class="btnContainer">
        <button class="btn" id="AirlinesButton" (click)="categoryButtonClicked('Airlines')"><img
            src="../../assets/images/partnership/airlinesBlack.svg" id="AirlinesIcon"
            class="buttonIcon">Airlines</button>
      </div>
      <div class="btnContainer">
        <button class="btn" id="HospitalityButton" (click)="categoryButtonClicked('Hospitality')"><img
            src="../../assets/images/partnership/hospitalityBlack.svg" id="HospitalityIcon"
            class="buttonIcon">Hospitality</button>
      </div>
      <div class="btnContainer">
        <button class="btn" id="CreditButton" (click)="categoryButtonClicked('Credit')"><img
            src="../../assets/images/partnership/creditCardBlack.svg" id="CreditIcon" class="buttonIcon">Credit Card
          Rewards</button>
      </div>
    </div>
    <div class="row categoryRow">
      <div class="col-12 col-lg-4">
        <h5 class="headerText categoryTitle">{{selectedCategory.title}}</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-4">
        <p class="detailsText">
          {{selectedCategory.details}}
        </p>
      </div>
      <div class="col-12 col-lg-8">
        <img class="img-fluid" src="{{selectedCategory.src}}">
        <div class="row snippetRow">
          <div *ngFor="let snippet of selectedCategory['snippets']" class="col-6">
            <img src="{{snippet.snippetHeader}}">
            <p class="snippetBody">{{snippet.snippetBody}}</p>
            <p class="snippetSource">{{snippet.snippetSource}}</p>
          </div>
        </div>
      </div>
    </div>
    <hr class="horizontalLine">
  </div>
</section>

<section class="journey">
  <div class="container-fluid">
    <h4 class="headerText">Own the Journey</h4>
    <div class="row">
      <div class="col-12 col-lg-4">
        <p class="journeyDetails">Connect with users through every step of the booking process.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <img class="img-fluid" src="../../assets/images/partnership/bookingProcess.png">
      </div>
    </div>
    <hr class="horizontalLine">
  </div>
</section>

<section class="integration">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6 align-self-center">
        <h4 class="headerText">Integration Partners</h4>
        <p class="detailsText">Do you have a platform that delivers rich content or bookable offerings targeted to
          travellers? <br><br> <span class="link"><a [routerLink]="'/support'" fragment="contact">Get in
              touch</a></span> with us to discuss the possibility of integrating with Krugo to expand your
          reach.</p>
      </div>
      <div class="col-12 col-md-6 integrationImage">
        <img src="../../assets/images/partnership/integrationPartners.png" class="img-fluid">
      </div>
    </div>
    <hr class="integrationLine">
  </div>
</section>

<div class="stickyFooter">
  <div class="container-fluid footerContainer">
    <div class="row">
      <div class="footerCaption col-md-4 col-6 align-self-center">
        <div>We'll show you how to grow your business through our partner solutions.</div>
      </div>
      <div class="footerButton col-2 offset-6 align-self-center">
        <button class="footer-button" (click)="requestDemo()">Request a Demo</button>
      </div>
    </div>
  </div>
</div>