<app-nav></app-nav>
<body>
  <nav class="navbar navbar-expand-sm legal-nav ">
    <ul class="navbar-nav">
      <li><a class="nav-link" [routerLink] = "'tos'" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Terms of Service</a></li>
      <li><a class="nav-link" [routerLink] = "'privacy'" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Privacy Policy</a></li>
      <li><a class="nav-link" [routerLink] = "'contest'" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Contest Rules</a></li>
      <li><a class="nav-link" [routerLink] = "'festival'" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">KrugoFest</a></li>
    </ul>
  </nav>
  <router-outlet></router-outlet>  
</body>