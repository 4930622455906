import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { EventGuardService } from '../services/event-guard.service';

import {AboutComponent} from './about/about.component';
import {LegalComponent} from './legal/legal.component';
import {PrivacyComponent} from './legal/privacy/privacy.component';
import {TosComponent} from './legal/tos/tos.component';
import {MediaComponent} from './media/media.component';
import {SupportComponent} from './support/support.component';
import {CompanyComponent} from './company/company.component';
import {PartnersComponent} from './partners/partners.component';
// import {ExploreComponent} from './explore/explore.component';
import {ContestComponent} from './legal/contest/contest.component';
import {EmailVerifiedComponent} from './emailverified/emailverified.component';
import {VerifyEmailExpiredComponent} from './verifyemailexpired/verifyemailexpired.component';
import {EventDetailComponent} from './event-detail/event-detail.component';
import { Explore2Component } from './explore2/explore2.component';
import { KrugofestComponent } from './krugofest/krugofest.component';
import {PlanComponent} from './krugofest/plan/plan.component';
import {PassesComponent} from './krugofest/passes/passes.component';
import {MusicComponent} from './krugofest/music/music.component';
import {SafetyComponent} from './krugofest/safety/safety.component';
import {FaqComponent} from './krugofest/faq/faq.component';
import {FestivalComponent} from './legal/festival/festival.component';

const routes: Routes = [
  {
    path: '', component: Explore2Component,
  },
  {
    path: 'about', component: AboutComponent
  },
  {
    path: 'legal', component: LegalComponent,
    children: [
      {
        path: 'tos',
        component: TosComponent
      },
      {
        path: 'privacy',
        component: PrivacyComponent
      },
      {
        path: 'contest',
        component: ContestComponent
      },
      {
        path: 'festival',
        component: FestivalComponent
      },
      {
        path: '**',
        redirectTo: 'tos',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'media', component: MediaComponent,
  },
  {
    path: 'support', component: SupportComponent,
  },
  {
    path: 'company', component: CompanyComponent,
  },
  {
    path: 'partners', component: PartnersComponent,
  },
  {
    path: 'krugofest', component: KrugofestComponent,
    children: [
      {
        path: 'plan',
        component: PlanComponent
      },
      {
        path: 'passes',
        component: PassesComponent
      },
      {
        path: 'music',
        component: MusicComponent
      },
      {
        path: 'safety',
        component: SafetyComponent
      },
      {
        path: 'faq',
        component: FaqComponent
      },
      {
        path: '**',
        redirectTo: 'plan',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'email-verified', component: EmailVerifiedComponent,
  },
  {
    path: 'verify-email-expired', component: VerifyEmailExpiredComponent,
  },
  {
    path: 'events',
    component: EventDetailComponent,
    canActivate: [EventGuardService],
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'disabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
