<app-explorenav></app-explorenav>
<div class="banner mb-3" [ngStyle]="{
  'background-image': 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + event.imageUrl + ')',
  'background-size': 'cover',
  'background-position': 'center'
}">
  <div class="container-fluid">
    <div class="row header-row">
      <div class="col-6 col-md-9">
        <h5 class="categoryHeader">{{event.categoryText}}</h5>
        <h2 class="titleHeader">{{event.eventTitle}}</h2>
      </div>
      <div class="col-2 buy-button align-self-end">
        <button class="search-button" [disabled]="disableButton ? 'disabled': null" (click)="buyTickets()">Buy
          Now</button>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid detail-row">
  <div class="row pb-3">
    <div class="col-6 col-md-3">
      <p class="detailTitle">Location</p>
      <div class="detailSubheader">{{event.venueTitle}}</div>
      <div class="detail-column">
        <div>{{event.venueAddress}}</div>
        <div>{{event.cityText}}</div>
        <div>{{event.dateText}}</div>
      </div>
    </div>
    <div class="col-6 col-md-3">
      <p class="detailTitle">Pricing</p>
      <div class="detailSubheader">{{getEventPrice()}}</div>
      <div class="detail-column">
        <div>Starting price (USD)</div>
        <div>{{event.ticketCount}} tickets remaining</div>
        <div>{{getUpdatedTime()}}</div>
      </div>
    </div>
    <div class="col-2">
    </div>
  </div>
  <hr>
</div>

<div class="container-fluid other-events" *ngIf="upcomingEvents.length > 0">
  <div class="row">
    <div class="col-12">
      <h3 class="otherEventsTitle">Related Events</h3>
    </div>
    <div class="col-12">
      <h5 class="otherEventsSubtitle">CHECK OUT THESE EVENTS ALSO HAPPENING IN {{getEventCity()}} AROUND THE SAME TIME
      </h5>
    </div>
  </div>
  <div class="upcomingEvents">
    <div class="row m-3 upcoming-row">
      <div class="card-flex-layout" fxLayout="row wrap" fxLayoutAlign="flex-start stretch" fxLayoutGap="2.5%">
        <div *ngFor="let upcomingEventVM of upcomingEvents let i=index" fxFlex.gt-md="0 1 calc(25% - 2.5%)"
          fxFlex.md="0 1 calc(33% - 2.5%)" fxFlex="0 1 calc(50% - 2.5%)" fxFlex.sm="0 1 calc(50% - 2.5%)"
          fxFlex.xs="0 1 100%" [fxHide]="!showAll && i>=limitedShowAmount">
          <div class="card" (click)="eventTapped(upcomingEventVM)">
            <img class="card-img-top upcoming-card-img" src="{{upcomingEventVM.imageUrl}}" alt="Performer Image">
            <div class="today-body" id="upcoming-body">
              <p class="today-category">
                {{upcomingEventVM.categoryText}}
              </p>
              <h5 class="today-title">{{upcomingEventVM.eventTitle}}</h5>
              <div class="today-location">
                <p class="today-venue">{{upcomingEventVM.venueTitle}}</p>
                <p class="today-city">{{upcomingEventVM.cityText}}</p>
                <p class="today-date">{{upcomingEventVM.dateText}}</p>
                <div class="today-cost">
                  <p class="ticket-cost" *ngIf="upcomingEventVM.ticketsAvailable">{{upcomingEventVM.priceText}}
                    <span *ngIf="upcomingEventVM.ticketsAvailable" class="USD">USD</span></p>
                  <p class="no-tickets" *ngIf="!upcomingEventVM.ticketsAvailable">No Tickets Available</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="buttonContainer" fxFlex="0 1 100%" fxFlexAlign="center" *ngIf="!showAll">
          <button class="showButton" (click)="showAll = true">Show More</button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #multipleModal>
  <div class="multipleModalHeader">
    <div class="modal-header no-border" id="modal-category">
      <h5 class="modal-title date-subtitle" id="multipleModalCategory">
        {{multipleEvents[0].categoryText}}</h5>
      <button type="button" class="close pull-right multipleClose" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-header no-border" id="modal-name">
      <h4 class="modal-title modal-performer">{{multipleEvents[0].events[0].primaryPerformer.name}}</h4>
    </div>
    <div class="modal-header">
      <h4 class="modal-title pick-event">Pick an Event</h4>
    </div>
  </div>
  <div class="modal-body" id="multipleModalBody">
    <div class="container-fluid event-card" *ngFor="let multipleEvent of multipleEvents[0].events; let i = index">
      <div class="full-card" (click)="multipleEventClicked(multipleEvents, i)" style="cursor: pointer;">
        <div class="row">
          <div class="col-6 card-details">
            <p class="card-date">{{getMultipleDate(multipleEvent)}} {{getMultipleTime(multipleEvent)}}</p>
            <p class="card-title">{{multipleEvent.eventName}}</p>
            <p class="card-venue">{{multipleEvent.venue.name}}</p>
          </div>
          <div class="col-5 align-self-center card-amount">
            <p class="card-price">{{getPlanCost(multipleEvent)}}</p>
            <p class="card-price-details" *ngIf="multipleEvent.minPrice>0">starting price (USD)</p>
          </div>
          <div class="align-self-center">
            <img class="img-fluid" id="right-arrow" src="./assets/images/explore/rightArrow.png">
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <p class="multipleModalFooter">Ticket information last updated within 30 minutes</p>
    </div>
  </div>
</ng-template>

<ng-template #vividModal>
  <div class="modal-header no-border" id="modal-category">
    <button type="button" class="close pull-right" aria-label="Close" (click)="vividModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-header no-border" id="modal-name">
    <img class="img-fluid" src="./assets/images/explore/vivid.png">
  </div>
  <div class="modal-body">
    <p class="vividModalBody">We are redirecting you to our ticket partner, Vivid Seats, for full details on these
      tickets.
      <br><br> Krugo and Vivid Seats partner to help you find great tickets to over 130,000 events.</p>
    <div class="text-center">
      <a href="{{this.vividLink}}" target="_blank" class="vividModalButton" (click)="vividModalRef.hide();">Got
        it!</a>
    </div>
  </div>
  <div class="text-center">
    <div class="vividModalFooter">
      <a href="{{this.vividLink}}" target="_blank" class="vividModalDismissButton"
        (click)="vividModalRef.hide(); setVividCookie()">Don't show this again</a>
    </div>
  </div>
</ng-template>

<!-- old sticky footer, may be able to re-use when we have multiple ticket providers -->
<!-- <div class="stickyFooter">
  <div class="container-fluid footerContainer">
    <div class="row">
      <div class="footerLogo col-md-2 col-2 align-self-center">
        <img class="img-fluid" src="./assets/images/explore/vivid.png">
      </div>
      <div class="footerTitle col-md-4 col-5 align-self-center">
        <div>{{event.eventTitle}}</div>
      </div>
      <div class="footerPrice col-md-2 offset-md-2 col-3 align-self-center">
        <div>{{getEventPrice()}}</div>
      </div>
      <div class="footerButton col-md-2 col-1 align-self-center">
        <button class="footer-button" [disabled]="disableButton ? 'disabled': null" (click)="buyTickets()">Buy
          Now</button>
      </div>
    </div>
  </div>
</div> -->

<div class="stickyFooter">
  <div class="container-fluid footerContainer">
    <div class="row">
      <div class="footerPrice col-md-9 col-6 align-self-center">
        <div>{{getEventPrice()}}</div>
        <div *ngIf="event.ticketCount != 0" class="footerStartingPrice">starting price (USD)</div>
      </div>
      <div class="footerButton col-2 align-self-center">
        <button class="footer-button" [disabled]="disableButton ? 'disabled': null" (click)="buyTickets()">Buy
          Now</button>
      </div>
    </div>
  </div>
</div>