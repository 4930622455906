import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-passes',
  templateUrl: './passes.component.html',
  styleUrls: ['./passes.component.scss']
})
export class PassesComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  ngOnInit() {
  }

  buttonClicked() {
    this.apiService.buyPasses();
  }

}
