<body>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-10 offset-lg-1">
        <hr class=nav-divider>
        <p class="section-header">
          Terms of Service
        </p>

        <p class="content">
            Version 1.0 posted and effective as of October 31, 2018  <br> <br>
            These terms of service (“Terms”) are a legal contract between you (the “User”, or “You”) and Krugo Marketing Inc. (“Krugo”, “We”). These terms govern your installation and use of the Krugo mobile application (“App”), as well as your access to and use of Krugo’s website (“Site”).<br><br>
            Please read this document carefully. By using the Service you accept and agree to be bound by the Terms.
        </p>

        <p class="sub-header">
            1. Changes to Terms
        </p>

        <p class="content">
            We may amend any part of these Terms by adding, deleting, or varying their content. These amendments may be made at any time and from time-to-time in our discretion. <br><br>
            If we are required by the laws in your country of residence, we will provide you with notice of the amendment, and in any case by posting an amended version of these Terms at www.krugotravel.com/termsofservice with a new version number. We will include a link to the previous version of the terms beneath the new version number. Any amendments take immediate effect unless statutory guarantees or consumer laws in your country provide otherwise, in which case they take effect after the minimum amount of time provided by law, but in every case not more than 30 days from when the amended version was posted. <br><br> 
            If you disagree with any amendments, you may terminate these Terms by uninstalling the App and ceasing to use the Services. There will be no cost or penalty for doing so. If you do not uninstall the App and cease using the Services, then by your continued use, you are considered to have accepted the amendments. Additionally, if you reinstall the App or begin using the Services again, you are considered to have accepted the amendments then in effect.
        </p>

        <p class="sub-header">
            2. Who Can Use our Services
        </p>

        <p class="content">
            Our Services are not intended for use by anyone under the age of majority. If you are considered a minor in the place where you live, you must only access the Services while under adult supervision and any purchases through the App must be approved by that adult. You must not otherwise use the Services in any way. Krugo may be used for both commercial and consumer purposes.
        </p>

        <p class="sub-header">
            3. Krugo Services and Third Party Purchases
        </p>

        <p class="content">
            “Services” has the meaning explained by this clause (including 3A, 3B, 3C and 3D), as offered and accessed by the User through the App, Site, and any other tools offered by Krugo. <br><br>

            <strong>3A. Travel Groups</strong><br> 
            Krugo allows you to create groups within the app in order to create joint travel plans. You can invite users to join Krugo, and your travel plans, via the app. You agree that you will only use Krugo to invite individuals who have explicitly expressed interest in utilizing the Services provided by Krugo. <br><br>
            
            <strong>3B. Trip Information</strong><br>
            Krugo allows you to access, individually or jointly with other Users, Trip Information: <br>
            
            - event schedules; <br>
            - event accommodations; and <br>
            - reservation information including pricing, location, and other information relevant to ensure you can plan a great trip. <br><br>
            
            <strong>3C. Related Transactions </strong><br>
            When a User accesses Trip Information, Krugo may connect you to, or allow you to access Third Party Transactions (“TPT”) including ticket and accommodation bookings. During any TPT: <br><br>
            
            - These terms do not apply;<br>
            - Krugo is not a party to the transaction;<br>
            - The terms of the third party apply and you are encouraged to review any applicable legal terms which governs your relationship with that third party;<br>
            - Krugo is not an agent or otherwise associated with any third party.<br><br>
            
            <strong>3D. Expense Records</strong><br>
            As part of the Services, Krugo allows Users to post debts and payments in relation to various expenses as they please between and with other users of the service (“Expense Records”). Expense Records are a notional value only and cannot be verified by Krugo. As such, You agree that these debts are not legally binding, and represent informal information sharing among friends or family. You agree that the Service cannot guarantee the accuracy of information entered by users.
        </p>

        <p class="sub-header">
            4. Use of the Services
        </p>

        <p class="content">
            <strong>4A. The App</strong><br>
            We give you permission to download a single copy of the App onto each one of your devices. Subject to your compliance with these terms, and for as long as we make the Services available, you are granted a limited, revocable, non-exclusive, non-transferrable right to use the Services. For greater certainty, this does not include any right to directly access the software, databases, algorithms or data which powers the Services and App, nor any right to obtain a copy of the source code or architecture. <br><br>
            
            We may update the App from time to time in order to improve the App and/or the Services. If you delay or refuse updates to the App, this may compromise or completely prevent us from delivering the Services. <br><br>
            
            <strong>4B. Acceptable Use of the Services</strong> <br>
            The following obligation applies to your use of the Services. When installing the App and using the Services, you must not: <br><br>
            
            i: decompile, disassemble, reverse engineer, or investigate the services or App or otherwise access any non-user interface portion of the App;<br>
            ii: circumvent any limitations we place on your use of the App or Services;<br>
            iii: use or display the App in such a way that this allows derivation of information about the Services that was not intended to be made available to users;<br>
            iv: circumvent any security features or technological protection measures built into the App;<br>
            v: automate access to the App or the Services, including, without limitation, through the use of APIs, bots, scrapers or other similar devices;<br>
            vi: attempt to install the App or access the Services after having been banned by us.
        </p>

        <p class="sub-header">
            5. Indemnification and Disclaimer of Warranties
        </p>

        <p class="content">
            Krugo does not represent that the Service is error-free, complete, or can be relied upon. Krugo retrieves Trip Information from third party partners and other sources to allow the User to centralize information about trip planning. While Krugo makes best efforts to ensure Trip Information is accurate and up to date, Krugo makes no warranties as to the accuracy or completeness of Trip Information and accepts no responsibility for any problems or damages that may occur through the use of the Services.<br><br>

            When you:<br>
            
            - use the Services for commercial or non-consumer purposes; and<br>
            - enter Related Transactions:<br><br>
            
            you agree to indemnify and hold harmless us and our officers, directors, employees, agents, and affiliated companies against any claims, suits, proceedings, disputes, demands, liabilities, damages, losses, costs and expenses, including reasonable legal and accounting fees caused by your use of the Services or breach of these Terms.<br><br>
            
            We are not a party to your contract with the third-party, nor do we act as agent for either you or that third party. We are merely an intermediary, and charge a small intermediary fee or commission.<br><br>
            
            THE SERVICE IS PROVIDED ‘AS IS’ AND ‘AS AVAILABLE’ WITH NO WARRANTY OF ANY KIND AND YOU ARE USING THE SERVICE AT YOUR OWN RISK. KRUGO DISCLAIMS ANY WARRANTY, IMPLIED OR OTHERWISE, REGARDING THE SERVICE, INCLUDING WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT.
        </p>

        <p class="sub-header">
            6. Limitation of Liability
        </p>
        
        <p class="content">
            UNDER NO CIRCUMSTANCES WILL KRUGO BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES (INCLUDING EMOTIONAL DISTRESS) ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SERVICE, WHETHER OR NOT YOU HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. UNDER NO CIRCUMSTANCES SHALL KRUGO BE LIABLE TO YOU FOR ANY AMOUNT FOR SERVICES RENDERED PURSUANT TO THIS AGREEMENT.
        </p>

        <p class="sub-header">
            7. Termination
        </p>

        <p class="content">
            <strong>a) Termination without Cause</strong><br>
            We may terminate these Terms without cause and without prior notice if we choose to discontinue the Services in whole or in part. We may also terminate these Terms if you violate the acceptable use policy in section 4 or otherwise materially breach these Terms. <br><br>
            
            You may terminate these Terms without cause at any time by uninstalling the App and ceasing to use the Services. If you reinstall the App or begin using the Services again, these Terms will apply. <br><br>
            
            <strong>b) Survival</strong><br>
            Sections 2, 3, 4, 5 (if applicable to you), 6, 7(b), and 8 will survive the termination of these Terms regardless of reason, and shall continue to bind both you and us.
        </p>

        <p class="sub-header">
          8. General
        </p>

        <p class="content">
            <strong>a) Governing law</strong><br>
            These Terms are governed exclusively by the domestic laws of the province of Saskatchewan and the federal laws of Canada applicable therein.<br><br>
            
            <strong>b) Dispute Resolution</strong><br>
            We are committed to customer satisfaction, so if you have a problem or dispute, we will try to resolve your concerns. All other litigation arising from or related to these Terms or the Services shall be submitted to the exclusive jurisdiction of the courts of Saskatchewan sitting in Regina.<br><br>
            
            <strong>c) Assignment</strong><br>
            You may not assign any of your rights arising under these Terms without our written consent.<br><br>
            
            <strong>d) Status of the Parties</strong><br>
            These Terms do not create any relationship of employment, partnership, agency, trust, franchise, or joint venture. Nor do they create any fiduciary duties. As explained above, Krugo is at most a re-marketing agent in certain transactions which occur via the App. Krugo is not directly contracting with you to provide ticket or accommodation services.<br><br>
            
            <strong>e) Entire Agreement</strong><br>
            These Terms (as amended from time to time) constitute the entire agreement between you and us regarding the Services. These Terms (and any amendments) replace and supersede any previous or existing agreement, contract, understanding, or representation regarding the Services.<br><br>
            
            For clarity, despite this Entire Agreement section, your purchases of event ticket or accommodations reservation services will be subject to separate third-party agreements.<br><br>
            
            <strong>f) Language</strong><br>
            You and us have agreed that these Terms and all related documents be drawn up in the English language.<br><br>
            
            <strong>g) Privacy</strong><br>
            We take privacy seriously. For more information on how we collect, use, and disclose personal information, please see our privacy policy.<br><br>
            
            <strong>h) Contact</strong><br>
            General inquiries may be sent to the following coordinates: <br>
            Krugo Marketing Inc. <br>
            603-1867 Hamilton Street <br>
            Regina, SK
        </p>



      </div>
    </div>
  </div>
</body>