<section class="passesHero">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6 align-self-center">
        <img class="img-fluid mb-1" src="../../../assets/images/krugofest/iconRow.svg">
        <p class="passesTitle">Want In?</p>
        <p class="passesDetails">The only thing that's stopping you from being at KrugoFest is a package. A package will
          get
          you and your friends access to one of the city's hottest attractions of the summer.
        </p>
        <button class="btn-primary" (click)="buttonClicked()">Buy Packages</button>
      </div>
      <div class="col-12 col-md-6 align-self-center">
        <img class="img-fluid img" src="../../../assets/images/krugofest/Package.png">
      </div>
    </div>
  </div>
  <hr class="bottomDivider">
</section>

<section class="details">
  <div class="container-fluid">
    <p class="detailHeader my-5">Each Package Includes:</p>
    <div class="row">
      <div class="col-12 col-lg-4 mb-3">
        <img class="img-fluid mb-3" src="../../../assets/images/krugofest/icons/yellow.svg">
        <p class="detailTitle">4 Tickets</p>
        <p class="detailsText">Don't worry, there's enough room for you and your bubble buddies inside each of the
          KrugoFest rooms.</p>
      </div>
      <div class="col-12 col-lg-4 mb-3">
        <img class="img-fluid mb-3" src="../../../assets/images/krugofest/icons/blue.svg">
        <p class="detailTitle">Private Guest Room</p>
        <p class="detailsText">A private guest-room with a balcony overlooking the rooftop performance down below.
          We’re talking real concert quality lights and sound.</p>
      </div>
      <div class="col-12 col-lg-4 mb-3">
        <img class="img-fluid mb-3" src="../../../assets/images/krugofest/icons/red.svg">
        <p class="detailTitle">Local Meals</p>
        <p class="detailsText">Your choice of three meals catered by local chefs (it’ll be ready for you to pick up on
          your way in).</p>
      </div>
      <div class="col-12 col-lg-4 mb-3">
        <img class="img-fluid mb-3" src="../../../assets/images/krugofest/icons/green.svg">
        <p class="detailTitle">BYO...Anything</p>
        <p class="detailsText">All of your own special extras -- bring your own snacks and pack up a cooler with some
          drinks. You’ve probably never done that at a concert before.</p>
      </div>
      <div class="col-12 col-lg-4 mb-3">
        <img class="img-fluid mb-3" src="../../../assets/images/krugofest/icons/purple.svg">
        <p class="detailTitle">A Good Night's Sleep</p>
        <p class="detailsText">The guest-room for the night because it is a hotel after all. Don't worry about finding
          a ride home if you've had a drink. Check-out is 10am sharp.</p>
      </div>
    </div>
  </div>
  <hr class="bottomDivider">
</section>