import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SwiperDirective } from 'ngx-swiper-wrapper';
import { ExploreService } from '../../services/explore.service';


@Component({
  selector: 'app-explore2',
  templateUrl: './explore2.component.html',
  styleUrls: ['./explore2.component.scss']
})
export class Explore2Component implements OnInit {
  selectedSection = null;
  oldSection = 'Experiences';
  deeplinkModalRef: BsModalRef;
  mobile = false;

  @ViewChild('deeplinkModal') deeplinkModal: TemplateRef<any>;
  constructor(private modalService: BsModalService, private exploreService: ExploreService) { }

  aboutSection: any[] = [
    {
      title: 'Experiences',
      subTitle: 'Plan ahead or search last-minute',
      details: 'Discover events, restaurants, activities and other things \n to do in more than 5,000 cities.',
      buttonTitle: 'Find More',
      src: '../../assets/images/explore2/experiencesImage.png'
    },
    {
      title: 'Plans',
      subTitle: 'Keep everything organized',
      details: 'Save trip itineraries and organize everything that you’ve got planned.',
      buttonTitle: 'Start Planning',
      src: '../../assets/images/explore2/plansImage.png'
    },
    {
      title: 'Groups',
      subTitle: 'Plan with friends',
      details: 'Create a group to build a shared itinerary, coordinate the details and split expenses.',
      buttonTitle: 'Plan Together',
      src: '../../assets/images/explore2/groupsImage.png'
    },
    {
      title: 'Stays',
      subTitle: 'Stay close to the action',
      details: 'Find the best places to stay based on everything you’ve got planned.',
      buttonTitle: 'Stay Smarter',
      src: '../../assets/images/explore2/staysImage.png'
    },
    {
      title: 'Guides',
      subTitle: 'Plan for the occasion',
      details: 'Browse personalized recommendations on the best local spots curated by Krugo Insiders.',
      buttonTitle: 'Browse Guides',
      src: '../../assets/images/explore2/guidesImage.png'
    },
    {
      title: 'Suggestions',
      subTitle: 'Suggestions for you',
      details: 'Travel to places where you can see your favourite bands and \n teams play, all in one weekend.',
      buttonTitle: 'Find Recommendations',
      src: '../../assets/images/explore2/suggestionsImage.png'
    },
  ];

  slides: any[] = [
    {
      src: '../../assets/images/explore2/mitchWalker.png',
      description: 'Seattle, WA',
      by: 'Mitch Walker'
    },
    {
      src: '../../assets/images/explore2/jakobOwens.png',
      description: 'Las Vegas, NV',
      by: 'Jakob Owens'
    },
    {
      src: '../../assets/images/explore2/marcosPaulo.png',
      description: 'Toronto, ON',
      by: 'Yasmine Arfaoui'
    },
    {
      src: '../../assets/images/explore2/rubenGutierrez.png',
      description: 'Los Angeles, CA',
      by: 'Ruben Gutierrez'
    },
    {
      src: '../../assets/images/explore2/kaiPilger.png',
      description: 'New York City, NY',
      by: 'Kai Pilger'
    },
    {
      src: '../../assets/images/explore2/lizLawrence.png',
      description: 'Chicago, IL',
      by: 'Liz Lawrence'
    },
    {
      src: '../../assets/images/explore2/kellySikkema.png',
      description: 'Boston, MA',
      by: 'Kelly Sikkema'
    },
    {
      src: '../../assets/images/explore2/jackChurch.png',
      description: 'Vancouver, BC',
      by: 'Jack Church'
    },
  ];

  swiperConfig: any = {
    initialSlide: 0,
    slidesPerView: 1,
    preloadImages: true,
    loop: true,
    autoplay: {
      delay: 7000,
    },
    fadeEffect: {
      crossFade: true
    },
    effect: 'fade'
  };

  @ViewChild(SwiperDirective) swiperRef: SwiperDirective;

  ngOnInit() {
    setTimeout(() => {
      this.categoryButtonClicked('Experiences');
    }, 1000);

    if ((window.innerWidth < 500) && !this.exploreService.seenModal) {
      this.openDeeplinkModal(this.deeplinkModal);
      this.exploreService.seenModal = !this.exploreService.seenModal;
    }

    if (navigator.maxTouchPoints > 0) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  categoryButtonClicked(category) {
    this.clearButton(this.oldSection);
    this.oldSection = category;
    this.selectedSection = this.aboutSection.find(element => element.title === category);
    const name = document.getElementById(category + 'Button');
    name.classList.add('btnClicked');
    const icon = document.getElementById(category + 'Icon');
    icon.classList.add('svg');
  }

  clearButton(oldCategory) {
    const name = document.getElementById(oldCategory + 'Button');
    name.classList.remove('btnClicked');
    const icon = document.getElementById(oldCategory + 'Icon');
    icon.classList.remove('svg');
  }

  deepLinkClicked() {
    if (navigator.userAgent.indexOf('iPhone') !== -1) {
      window.open('https://itunes.apple.com/us/app/krugo/id1393726426?mt=8');
    } else if (navigator.userAgent.indexOf('Android') !== -1) {
      window.open('https://play.google.com/store/apps/details?id=com.krugo.krugo');
    } else {
      window.open('https://www.krugoapp.com');
    }
  }

  openDeeplinkModal(template: TemplateRef<any>) {
    const config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false
    };
    this.deeplinkModalRef = this.modalService.show(template, config);
  }



}
