import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  selectedPlanning = null;
  oldPlanning = 'planning1';
  selectedExplore = null;
  oldExplore = 'explore1';
  selectedSuggestion = null;
  oldSuggestion = 'suggestion1';
  mobile = false;

  constructor() { }

  planningFeatures: any[] = [
    {
      title: 'planning1',
      src: '../../assets/images/app/App 1_1.png'
    },
    {
      title: 'planning2',
      src: '../../assets/images/app/App 1_2.png'
    },
    {
      title: 'planning3',
      src: '../../assets/images/app/App 1_3.png'
    }
  ];

  exploreFeatures: any[] = [
    {
      title: 'explore1',
      src: '../../assets/images/app/App 2_1.png'
    },
    {
      title: 'explore2',
      src: '../../assets/images/app/App 2_2.png'
    },
    {
      title: 'explore3',
      src: '../../assets/images/app/App 2_3.png'
    }
  ];

  suggestionFeatures: any[] = [
    {
      title: 'suggestion1',
      src: '../../assets/images/app/App 3_1.png'
    },
    {
      title: 'suggestion2',
      src: '../../assets/images/app/App 3_2.png'
    },
    {
      title: 'suggestion3',
      src: '../../assets/images/app/App 3_3.png'
    }
  ];

  ngOnInit() {
    setTimeout(() => {
      this.planningClicked('planning1');
      this.exploreClicked('explore1');
      this.suggestionClicked('suggestion1');
    }, 1000);

    if (navigator.maxTouchPoints > 0) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  planningClicked(category) {
    const image = document.getElementById('planningImage');
    image.style.animation = 'none';
    image.offsetHeight; /* trigger reflow */
    image.style.animation = null;
    this.clearButton(this.oldPlanning);
    this.oldPlanning = category;
    this.selectedPlanning = this.planningFeatures.find(element => element.title === category);
    const box = document.getElementById(category);
    box.classList.add('selectedBox');
    const text = document.getElementById(category + 'Text');
    text.classList.add('selectedText');
  }

  exploreClicked(category) {
    const image = document.getElementById('exploreImage');
    image.style.animation = 'none';
    image.offsetHeight; /* trigger reflow */
    image.style.animation = null;
    this.clearButton(this.oldExplore);
    this.oldExplore = category;
    this.selectedExplore = this.exploreFeatures.find(element => element.title === category);
    const box = document.getElementById(category);
    box.classList.add('selectedBox');
    const text = document.getElementById(category + 'Text');
    text.classList.add('selectedText');
  }

  suggestionClicked(category) {
    const image = document.getElementById('suggestionImage');
    image.style.animation = 'none';
    image.offsetHeight; /* trigger reflow */
    image.style.animation = null;
    this.clearButton(this.oldSuggestion);
    this.oldSuggestion = category;
    this.selectedSuggestion = this.suggestionFeatures.find(element => element.title === category);
    const box = document.getElementById(category);
    box.classList.add('selectedBox');
    const text = document.getElementById(category + 'Text');
    text.classList.add('selectedText');
  }

  clearButton(oldCategory) {
    const box = document.getElementById(oldCategory);
    box.classList.remove('selectedBox');
    const text = document.getElementById(oldCategory + 'Text');
    text.classList.remove('selectedText');
  }

  deepLinkClicked() {
    if (navigator.userAgent.indexOf('iPhone') !== -1) {
      window.open('https://itunes.apple.com/us/app/krugo/id1393726426?mt=8');
    } else if (navigator.userAgent.indexOf('Android') !== -1) {
      window.open('https://play.google.com/store/apps/details?id=com.krugo.krugo');
    } else {
      window.open('https://www.krugoapp.com');
    }
  }

}
