<app-nav></app-nav>
<section class="splash" id="app">
    <div class="container-fluid">
        <div class="splashContainer">
            <div class="row splashRow">
                <div class="col-11 offset-1 col-lg-4 offset-lg-7 my-auto">
                    <p class="splashTitle">Find things to do in 5,000 cities.</p>
                    <p class="splashSub">Get excited about the things you love doing and find more of it wherever you
                        go.</p>
                    <button *ngIf="mobile" class="btn-primary" (click)="deepLinkClicked()">Join for Free</button>
                    <app-sendlink *ngIf="!mobile" label="Join for Free"></app-sendlink>
                </div>
            </div>
        </div>
        <hr class="horizontalLine">
    </div>
</section>

<section class="video">
    <div class="container-fluid">
        <div class="row flipRow">
            <div class="col-lg-8 col-12">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/321887461" frameborder="0"
                        allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
            </div>
            <div class="col-lg-4 col-12 align-self-center">
                <p class="videoText">24 hours in New York City with Justin & Greg</p>
                <p class="featureSub">This quick trip had it all! One day, two friends and the sights, sounds and smells
                    of New York. What would you do in NYC if you only had 24 hours?</p>
            </div>
        </div>
        <hr class="horizontalLine">
    </div>
</section>

<section class="planning">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 align-self-center">
                <p class="featureTitle">Planning</p>
                <p class="featureHeader">Stay organized and plan together</p>
                <p class="featureSub">Pick a city and we’ll make it easy to discover, plan and share everything you want
                    to do.</p>
                <div class="row pointerCursor" id="planning1" (click)="planningClicked('planning1')">
                    <div class="svgIcon">
                        <img class="img-fluid icon" src="../../assets/images/app/planning1.svg">
                    </div>
                    <div class="col-lg-10">
                        <p class="featureText" id="planning1Text">Find out what’s happening wherever you go.</p>
                    </div>
                </div>
                <hr class="featureLine">
                <div class="row pointerCursor" id="planning2" (click)="planningClicked('planning2')">
                    <div class="svgIcon">
                        <img class="img-fluid icon" src="../../assets/images/app/planning2.svg">
                    </div>
                    <div class="col-lg-10">
                        <p class="featureText" id="planning2Text">Share ideas, plan together and keep track of expenses.
                        </p>
                    </div>
                </div>
                <hr class="featureLine">
                <div class="row pointerCursor" id="planning3" (click)="planningClicked('planning3')">
                    <div class="svgIcon">
                        <img class="img-fluid icon" src="../../assets/images/app/planning3.svg">
                    </div>
                    <div class="col-lg-10">
                        <p class="featureText" id="planning3Text">Add things to do and manage your itinerary.</p>
                    </div>
                </div>
                <hr class="featureLine">
            </div>
            <div class="col-lg-7">
                <img class="img-fluid selectedImage fade-in" id="planningImage" src="{{selectedPlanning.src}}">
            </div>
        </div>
        <hr class="horizontalLine">
    </div>
</section>

<section class="planning">
    <div class="container-fluid">
        <div class="row flipRow">
            <div class="col-lg-7">
                <img class="img-fluid selectedImage fade-in" id="exploreImage" src="{{selectedExplore.src}}">
            </div>
            <div class="col-lg-5 align-self-center">
                <p class="featureTitle">Explore</p>
                <p class="featureHeader">Plan ahead or search last minute</p>
                <p class="featureSub">Count on Krugo to let you know about all the things you might be interested in
                    doing.</p>
                <div class="row pointerCursor" id="explore1" (click)="exploreClicked('explore1')">
                    <div class="svgIcon">
                        <img class="img-fluid icon" src="../../assets/images/app/explore1.svg">
                    </div>
                    <div class="col-lg-10">
                        <p class="featureText" id="explore1Text">Discover sports, concerts and theatre events.</p>
                    </div>
                </div>
                <hr class="featureLine">
                <div class="row pointerCursor" id="explore2" (click)="exploreClicked('explore2')">
                    <div class="svgIcon">
                        <img class="img-fluid icon" src="../../assets/images/app/explore2.svg">
                    </div>
                    <div class="col-lg-10">
                        <p class="featureText" id="explore2Text">Browse tips on top restaurants and nightlife.
                        </p>
                    </div>
                </div>
                <hr class="featureLine">
                <div class="row pointerCursor" id="explore3" (click)="exploreClicked('explore3')">
                    <div class="svgIcon">
                        <img class="img-fluid icon" src="../../assets/images/app/explore3.svg">
                    </div>
                    <div class="col-lg-10">
                        <p class="featureText" id="explore3Text">Find the best places to stay and book instantly.</p>
                    </div>
                </div>
                <hr class="featureLine">
            </div>
        </div>
        <hr class="horizontalLine">
    </div>
</section>

<section class="planning">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 align-self-center">
                <p class="featureTitle">Suggestions</p>
                <p class="featureHeader">Tips and recommendations just for you</p>
                <p class="featureSub">Live for the chase and focus on the experiences that are worth travelling for.</p>
                <div class="row pointerCursor" id="suggestion1" (click)="suggestionClicked('suggestion1')">
                    <div class="svgIcon">
                        <img class="img-fluid icon" src="../../assets/images/app/suggestion1.svg">
                    </div>
                    <div class="col-lg-10">
                        <p class="featureText" id="suggestion1Text">Browse trip ideas built around all of the things you
                            like to do.</p>
                    </div>
                </div>
                <hr class="featureLine">
                <div class="row pointerCursor" id="suggestion2" (click)="suggestionClicked('suggestion2')">
                    <div class="svgIcon">
                        <img class="img-fluid icon" src="../../assets/images/app/suggestion2.svg">
                    </div>
                    <div class="col-lg-10">
                        <p class="featureText" id="suggestion2Text">Check out curated tips on the best local spots for
                            any
                            occasion.
                        </p>
                    </div>
                </div>
                <hr class="featureLine">
                <div class="row pointerCursor" id="suggestion3" (click)="suggestionClicked('suggestion3')">
                    <div class="svgIcon">
                        <img class="img-fluid icon" src="../../assets/images/app/suggestion3.svg">
                    </div>
                    <div class="col-lg-10">
                        <p class="featureText" id="suggestion3Text">Get notified about cool things happening wherever
                            you
                            are.</p>
                    </div>
                </div>
                <hr class="featureLine">
            </div>
            <div class="col-lg-7">
                <img class="img-fluid selectedImage fade-in" id="suggestionImage" src="{{selectedSuggestion.src}}">
            </div>
        </div>
        <hr class="horizontalLine">
    </div>
</section>

<section class="nextTrip">
    <div class="container-fluid">
        <div class="nextTripContainer">
            <div class="row">
                <div class="col-11 offset-1 col-lg-7 offset-lg-1 mt-5">
                    <p class="tripText">Your next trip begins with the krugo app.</p>
                </div>
            </div>
        </div>
    </div>
</section>