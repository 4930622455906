// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appName: 'krugo-staging',
  production: false,
  apiBaseUrl: 'https://rx8cx3icl7.execute-api.us-west-2.amazonaws.com/staging',
  routerBaseUrl: 'https://yft3wp8uv9.execute-api.us-west-2.amazonaws.com/staging',
  emailVerified: 'https://staging.krugo.link/?link=https%3A//staging.krugoapp.com/%23/email-verified',
  emailExpired: 'https://staging.krugo.link/?link=https%3A//staging.krugoapp.com/%23/verify-email-expired',
  buyPasses: 'https://staging.krugo.link/3RRt',
  firebaseConfig: {
    apiKey: 'AIzaSyD8vq9XDPtrBqKZsAUdzLlXqGzvUi5nUdE',
    authDomain: 'krugo-staging-238620.firebaseapp.com',
    databaseURL: 'https://krugo-staging-238620.firebaseio.com',
    projectId: 'krugo-staging-238620',
    storageBucket: '',
    messagingSenderId: '594346995070',
    appId: '1:594346995070:web:f6e7f3cf67e0fa2291172f',
    measurementId: 'G-G2MMWR1BNW'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
