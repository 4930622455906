<section class="safetyHero" id="safety">
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12 col-lg-6 align-self-center">
        <p class="safetyTitle">Safety</p>
        <p class="safetyDetails">Krugo is all about the good times. That's why we decided to throw this one of a kind
          event. Safety is the cornerstone of KrugoFest, and we did not take it lightly. By adhering to the rules and
          guidelines, the only thing you need to worry about is keeping the drinks cold, and the snacks endless. We are
          all in this together, and together we can make sure that everyone has a good time.
        </p>
      </div>
      <div class="col-12 col-lg-6 align-self-center">
        <img class="img-fluid img" src="../../../assets/images/krugofest/safety.png">
      </div>
    </div>
    <hr class="bottomDivider">
  </div>
</section>

<section class="my-5">
  <div class="container-fluid">
    <p class="detailsTitle">What to Expect</p>
    <div class="row textRow">
      <div class="col-md-6 col-12">
        <p class="safetyDetails">At KrugoFest, safety is our top priority. While we want to encourage you to have a good
          time, we will be taking some extra precautions to make sure everyone can enjoy the experience safely. <br><br>
          The Saskatchewan Health Authority reviewed the safety plan to confirm that it is in compliance with the
          current provincial COVID-19 Public Health Order. The unique configuration
          of the DoubleTree by Hilton™ is perfect for this event. Attendees will be safely spread across over 100 rooms
          with private balconies that overlook the location of the stage on an adjacent rooftop.</p>
      </div>
      <div class="col-md-6 col-12">
        <p class="safetyDetails"> We’re all in this together! All KrugoFest attendees will be asked to adhere to the
          health and safety procedures that we’ve developed so that we can all safely share in one of the most memorable
          events of the summer.
        </p>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-4 mb-5">
        <img class="img-fluid mb-3" src="../../../assets/images/krugofest/safety/limited.svg">
        <p class="itemTitle">Limited Capacity</p>
        <p class="itemDetails">Capacity of each room is strictly limited to four attendees. Attendees are asked to
          attend with others from their household or extended social bubble.</p>
      </div>
      <div class="col-12 col-lg-4 mb-5">
        <img class="img-fluid mb-3" src="../../../assets/images/krugofest/safety/assigned.svg">
        <p class="itemTitle">Assigned Arrival Times</p>
        <p class="itemDetails">Each group of attendees will have a set time to arrive at the event. This will ensure we
          avoid having large groups of people together in common areas.</p>
      </div>
      <div class="col-12 col-lg-4 mb-5">
        <img class="img-fluid mb-3" src="../../../assets/images/krugofest/safety/masks.svg">
        <p class="itemTitle">Masks in Public Areas</p>
        <p class="itemDetails">Attendees will be required to wear masks when checking in and as they make their way to
          their private guest-room. You can pick up free masks on arrival.</p>
      </div>
      <div class="col-12 col-lg-4 mb-5">
        <img class="img-fluid mb-3" src="../../../assets/images/krugofest/safety/enhanced.svg">
        <p class="itemTitle">Enhanced Cleaning</p>
        <p class="itemDetails">Hotel guest rooms are subject to an enhanced level of cleaning including <a
            href="https://newsroom.hilton.com/corporate/news/hilton-defining-new-standard-of-cleanliness">Hilton’s
            CleanStay Pledge</a>.</p>
      </div>
      <div class="col-12 col-lg-4 mb-5">
        <img class="img-fluid mb-3" src="../../../assets/images/krugofest/safety/event.svg">
        <p class="itemTitle">Event Security</p>
        <p class="itemDetails">Professional security staff and volunteer ambassadors will be on hand to ensure safe use
          of shared spaces.</p>
      </div>
      <div class="col-12 col-lg-4 mb-5">
        <img class="img-fluid mb-3" src="../../../assets/images/krugofest/safety/dedicated.svg">
        <p class="itemTitle">Dedicated Rooms</p>
        <p class="itemDetails">Attendees will be asked to stay in their room for the duration of the concert - moving
          between guestrooms is strictly prohibited.</p>
      </div>
      <div class="col-12 col-lg-4 mb-5">
        <img class="img-fluid mb-3" src="../../../assets/images/krugofest/safety/check.svg">
        <p class="itemTitle">Check-In Process</p>
        <p class="itemDetails">Questioning guests on arrival to ensure that no-one has been exposed to COVID-19. This
          information will be recorded.</p>
      </div>
      <div class="col-12 col-lg-4 mb-5">
        <img class="img-fluid mb-3" src="../../../assets/images/krugofest/safety/coordinated.svg">
        <p class="itemTitle">Coordinated Departures</p>
        <p class="itemDetails">Guests leaving the hotel after the show is over will be asked to adhere to physical
          distancing in shared spaces. Be patient and follow the instructions of security staff.</p>
      </div>
      <div class="col-12 col-lg-4 mb-5">
        <img class="img-fluid mb-3" src="../../../assets/images/krugofest/safety/proactive.svg">
        <p class="itemTitle">Proactive Communications</p>
        <p class="itemDetails">Attendees can expect to hear from us ahead of the event with important details about
          their obligations to each other so we can all enjoy this unique VIP experience.</p>
      </div>
    </div>
    <hr class="bottomDivider">
  </div>
</section>