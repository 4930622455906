import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import * as firebase from 'firebase/app';
import 'firebase/analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'Krugoweb';

  constructor() {
    firebase.initializeApp(environment.firebaseConfig);
    const userProperties: { [key: string]: any; } = {};
    userProperties['Platform'] = 'Web';
    firebase.analytics().setUserProperties(userProperties);
  }
}
