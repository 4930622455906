import { Component, OnInit, TemplateRef, Input, ViewEncapsulation } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-sendlink',
  templateUrl: './sendlink.component.html',
  styleUrls: ['./sendlink.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SendlinkComponent implements OnInit {

  modalRef: BsModalRef;
  public initialDisplay = true;
  public sentDisplay = false;
  @Input() label: string;

  constructor(private modalService: BsModalService, private ApiService: ApiService) { }

  openModal(template: TemplateRef<any>) {
    this.initialDisplay = true;
    this.sentDisplay = false;
    this.modalRef = this.modalService.show(template);
  }

  ngOnInit() {
  }

  onSubmit(phoneNumber: string) {
    this.ApiService.sendDownloadLink(phoneNumber).subscribe(result => {
      console.log(result);
    }, error => {
      console.error(error);
    });

    this.initialDisplay = false;
    this.sentDisplay = true;

  }

}
