import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { DropdownModule } from 'angular-custom-dropdown';
// import { SwiperModule } from 'ngx-useful-swiper';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { NgxFreshChatModule } from 'ngx-freshchat';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CookieService } from 'ngx-cookie-service';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FlexLayoutModule } from '@angular/flex-layout';
import * as firebase from 'firebase';
import { SafePipeModule } from 'safe-pipe';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';



import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { LegalComponent } from './legal/legal.component';
import { PrivacyComponent } from './legal/privacy/privacy.component';
import { TosComponent } from './legal/tos/tos.component';
import { SendlinkComponent } from './sendlink/sendlink.component';
import { MediaComponent } from './media/media.component';

import { ApiService } from '../services/api.service';
import { SupportComponent } from './support/support.component';
import { CompanyComponent } from './company/company.component';
import { PartnersComponent } from './partners/partners.component';
import { ExploreComponent } from './explore/explore.component';
import { ExploreService } from '../services/explore.service';
import { KrugoImageService } from '../services/image.service';
import { ContestComponent } from './legal/contest/contest.component';
import { EmailVerifiedComponent } from './emailverified/emailverified.component';
import { VerifyEmailExpiredComponent } from './verifyemailexpired/verifyemailexpired.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { ExploreNavComponent } from './explorenav/explorenav.component';
import { StickyheaderComponent } from './stickyheader/stickyheader.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { Explore2Component } from './explore2/explore2.component';
import { KrugofestComponent } from './krugofest/krugofest.component';
import { PlanComponent } from './krugofest/plan/plan.component';
import { PassesComponent } from './krugofest/passes/passes.component';
import { MusicComponent } from './krugofest/music/music.component';
import { SafetyComponent } from './krugofest/safety/safety.component';
import { FaqComponent } from './krugofest/faq/faq.component';
import { FestivalComponent } from './legal/festival/festival.component';


@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    NavComponent,
    FooterComponent,
    LegalComponent,
    PrivacyComponent,
    TosComponent,
    SendlinkComponent,
    MediaComponent,
    SupportComponent,
    CompanyComponent,
    PartnersComponent,
    ExploreComponent,
    ContestComponent,
    EmailVerifiedComponent,
    VerifyEmailExpiredComponent,
    LoadingSpinnerComponent,
    ExploreNavComponent,
    StickyheaderComponent,
    EventDetailComponent,
    Explore2Component,
    KrugofestComponent,
    PlanComponent,
    PassesComponent,
    MusicComponent,
    SafetyComponent,
    FaqComponent,
    FestivalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DropdownModule,
    ModalModule.forRoot(),
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    HttpClientModule,
    SwiperModule,
    NgxFreshChatModule,
    BrowserAnimationsModule,
    AccordionModule.forRoot(),
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    FlexLayoutModule,
    SafePipeModule,
    NgxShimmerLoadingModule
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    ApiService,
    ExploreService,
    KrugoImageService,
    CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
