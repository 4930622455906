<body>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-10 offset-lg-1">
        <hr class=nav-divider>
        <p class="section-header">
          Privacy Policy
        </p>

        <p class="content">
          Version 1.0 posted and effective as of October 31, 2018 
        </p>

        <p class="sub-header">
          1. Information Collected By Krugo
        </p>

        <p class="content">
          Krugo Marketing Inc. (“Krugo”, “We”, “Us”) collects personal information: <br><br>

          <strong>A. When Users (“You”, “Your”) give it to Us directly or permission to obtain it.</strong><br><br>

          When you sign up for or use our products, you voluntarily give us certain information, sometimes including
          your first and last name, telephone number, and email addresses.<br> <br>

          When you give us your phone number and email address, you will be automatically opted in to receive email
          notices, which may include notifications, announcements, and newsletters. We do not use this email address for
          any purpose other than to send you email. If you do not want to receive email from us, simply unsubscribe by
          contacting us at <a href="mailto:hello@krugoapp.com">hello@krugoapp.com</a>, or update your account settings. <br><br>

          If you make a booking or purchase for event tickets, accommodations or other products through one of Krugo’s
          third-party partners, you may be requested to provide additional personal information and traveller preference
          information. <br><br>

          Once you navigate through to third party websites, applications, or other medium for “Related Transaction” as
          defined in Krugo’s Terms of Service, this Privacy Policy does not apply, including to the extent that you
          provide further personal information to that third party. <br><br>

          - Transactional information, such as credit card number, zip/postal code, cardholder name, and expiration
          date.<br>
          - Personally identifiable information, such as your first and last name, date of birth, and gender as required
          by TSA Secure Flight Program.<br>
          - Traveler companions: When you make a booking or purchase on behalf of someone else, you might be requested
          to provide personal information and travel preferences about that individual including first and last name,
          gender, and date of birth. You should obtain the consent of other individuals prior to providing Krugo or
          Krugo’s third-party partners with their personal information and travel preferences, as any access to view or
          change their information may be available only through your account. <br><br>

          <strong>B. Technical information when You use the Service.</strong><br>

          There’s certain information that is produced and recorded automatically through the course of using Krugo.
          Here are some of the types of information we collect: <br><br>

          - Log Data: When you use Krugo, our servers automatically record information (“log data”) including
          information that your mobile app sends when you’re using it. This log data may include your Internet Protocol
          address, cookie data, and other related information to help us improve the Service.<br>
          - Device Information: We may collect information about the device You use to access the Service.<br>
          - Tracking Tools: Krugo uses tracking tools for following purposes:<br>
          a) To track when you book on partner websites.<br>
          b) To track user search behaviours and patterns.<br>
          c) To store identifying information such as preferences in order to make Your experience using the Service
          better.
        </p>

        <p class="sub-header">
          2. Use of Personal Information
        </p>

        <p class="content">
          We use the information we collect to: <br><br>

          - To share with individuals who you are jointly using the Service with.<br>
          - To display more relevant advertising and recommendations, or suppress advertising and content that you might
          find irrelevant.<br>
          - Complete bookings and purchases you fulfill through our third-party partners, to provide you with the
          products and services you request; and to provide you with purchase confirmation and updates; to manage your
          account, including processing payment information and providing notifications.<br>
          - Send you updates (such as when we identify a trip that you might be interested in), newsletters, marketing
          materials and other information that may be of interest to you. You can decide to stop getting these updates
          by unsubscribing by emailing us at <a href="mailto:hello@krugoapp.com">hello@krugoapp.com</a> or updating your account settings.<br><br>

          We use the Information we collect to provide our products to you and make them better, develop new products,
          and protect Krugo and our users.<br><br>

          <strong>A. “Information” includes:</strong><br>
          - Improving your experience when using the services.<br>
          - Connect you with vendors to make purchases you’ve selected when using the Services (see “Related
          Transactions” in Our Terms of Service)<br>
          - Send you updates, newsletters, marketing materials and other information that may be of interest to you. You
          can choose to stop receiving this information at any time.<br>
          - The information we collect may be “personally identifiable” (meaning it can be used to specifically identify
          you as a unique person) or “non-personally identifiable” (meaning it can’t be used to specifically identify
          you). We use both types of information, and combinations of both types, as described above. We may use or
          store information wherever Krugo does business, including countries outside your own.<br><br>

          <strong>B. Choices About Your Information</strong><br>
          - Through your device settings where available, you can choose not to provide personally identifiable
          information to us; however, doing so may limit the effectiveness of the services provided by Krugo. <br><br>

          <strong>C. Sharing the Information We Collect</strong> <br>
          We may share your information with:<br><br>

          a) Third-party partners (e.g. suppliers who fulfill your bookings and purchases).<br>
          b) Partner vendors who provide services or functions on our behalf or on behalf of our part, including credit
          card processing, business analytics, customer service, and fraud prevention.<br>
          c) We may share your personal information with our corporate affiliates and with third party partners for our
          legitimate business purposes as permitted by law.<br>
          d) If we believe that disclosure is reasonably necessary to comply with a law, regulation or legal request; to
          protect the safety, rights, or property of the public, any person, or Krugo; or to detect, prevent, or
          otherwise address fraud, security or technical issues.
        </p>

        <p class="sub-header">
          3. Protecting Your Information
        </p>

        <p class="content">
          We want you to feel comfortable about using Krugo, and we are committed to protecting the information we
          collect. We have implemented appropriate administrative, technical, and physical security procedures to help
          protect the personal information you provide to us. We use encryption when transmitting your sensitive
          personal information between your system, ours, and third party partners.
        </p>

        <p class="sub-header">
          4. How Do We Make Changes to This Policy?
        </p>

        <p class="content">
          We may change this policy from time to time, and if we do we’ll post any changes on this page. If you continue
          to use Krugo after those changes are in effect, you agree to the revised policy. If the changes are
          significant, we may provide more prominent notice or consent as required by law.
        </p>

        <p class="sub-header">
            5. Contact
        </p>
        
        <p class="content">
          To contact us about the personal information we hold about you, or if you have any questions about this
          Privacy Policy, please email <a href="mailto:hello@krugoapp.com">hello@krugoapp.com</a>.
        </p>

      </div>
    </div>
  </div>
</body>