<section class="musicHero">
  <div class="contatiner-fluid">
    <div class="row buyRow">
      <div class="col-12 col-lg-4">
        <img class="img-fluid img mb-4" src="../../../assets/images/krugofest/JessMoskaluke.jpg">
        <p class="weekTitle">Jess Moskaluke</p>
        <p class="weekDetails mb-4">Musical performance from Jess Moskaluke on Thursday, August 6,
          2020</p>
        <button class="btn-primary" (click)="buyJess()">Buy Packages</button>
      </div>
      <div class="col-12 col-lg-4">
        <img class="img-fluid img mb-4" src="../../../assets/images/krugofest/CorbLund.jpg">
        <p class="weekTitle">Corb Lund</p>
        <p class="weekDetails mb-4">Musical performance from Corb Lund on Saturday, August 8,
          2020 </p>
        <button class="btn-primary" (click)="buyCorb()">Buy Packages</button>
      </div>
      <div class="col-12 col-lg-4">
        <img class="img-fluid img mb-4" src="../../../assets/images/krugofest/Sheepdogs.jpg">
        <p class="weekTitle">The Sheepdogs</p>
        <p class="weekDetails mb-4">Musical performances from The Sheepdogs on Friday, August 14,
          and Saturday, August 15, 2020 </p>
        <!-- <button class="btn-primary" (click)="buttonClicked()">Buy Packages</button> -->
        <button class="btn-primary" (click)="openModal(template)">Buy Packages</button>
      </div>
    </div>
    <hr>
  </div>
</section>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title">Select Your Date:</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6">
          <button class="modalButton" (click)="buySheepdogs('friday')">Friday, August 14</button>
        </div>
        <div class="col-6">
          <button class="modalButton" (click)="buySheepdogs('saturday')">Saturday, August 15</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- <section class="artists">
  <div class="container-fluid">
    <p class="weekTitle">SUPPORTING CANADIAN ARTISTS</p>
    <p class="weekDetails">Help support KrugoFest performers by visiting their bandcamp pages.</p>
    <div class="row artistRow mb-5">
      <div class="col-12 col-lg-4">
        <img class="img-fluid" src="../../../assets/images/krugofest/jess.png">
      </div>
      <div class="col-12 col-lg-4">
        <img class="img-fluid" src="../../../assets/images/krugofest/jess.png">
      </div>
      <div class="col-12 col-lg-4">
        <img class="img-fluid" src="../../../assets/images/krugofest/jess.png">
      </div>
      <div class="col-12 col-lg-4">
        <img class="img-fluid" src="../../../assets/images/krugofest/jess.png">
      </div>
      <div class="col-12 col-lg-4">
        <img class="img-fluid" src="../../../assets/images/krugofest/jess.png">
      </div>
      <div class="col-12 col-lg-4">
        <img class="img-fluid" src="../../../assets/images/krugofest/jess.png">
      </div>
      <div class="col-12 col-lg-4">
        <img class="img-fluid" src="../../../assets/images/krugofest/jess.png">
      </div>
      <div class="col-12 col-lg-4">
        <img class="img-fluid" src="../../../assets/images/krugofest/jess.png">
      </div>
      <div class="col-12 col-lg-4">
        <img class="img-fluid" src="../../../assets/images/krugofest/jess.png">
      </div>
    </div>
    <hr>
  </div>
</section> -->