<section class="header-container">
  <div class="container-fluid">
    <div class="row">
      <div class="spacer"></div>
      <div class="col-lg-8 col-12 bannerImage">
        <ngx-shimmer-loading [hidden]="posts" [width]="'30rem'" [height]="'30rem'"></ngx-shimmer-loading>
        <img class="img-fluid" src="{{firstPost.imageUrl}}">
      </div>
      <div class="col-lg-3 col-12 align-self-center">
        <ngx-shimmer-loading [hidden]="posts" [width]="'30rem'" [height]="'30rem'"></ngx-shimmer-loading>
        <h3 class="post-title">
          {{firstPost.title}}
        </h3>
        <span class="post-meta">
          {{firstPost.subtitle}} on {{firstPost.firstPublishedAt | date}}
        </span>
        <ngx-shimmer-loading [hidden]="posts" [width]="'30rem'" [height]="'30rem'"></ngx-shimmer-loading>
        <div class="readNowButton">
          <a *ngIf='postsLoaded' href="{{firstPost.postUrl}}" class="read-button">Read Now</a>
        </div>
      </div>
    </div>
    <hr class="hr">
  </div>
</section>

<section class="latestNews" id="latest-news">
  <div class="container-fluid">
    <h1 class="title">Latest News</h1>
    <div class="row mt-4 mb-5">
      <div class="col-lg-4 col-12 media-post">
        <a
          href="https://leaderpost.com/news/local-news/reginas-tech-renaissance-how-a-new-batch-of-startups-try-to-become-the-next-big-thing">
          <img src="./assets/images/media/leaderpost.jpg" alt="Regina's tech renaissance" class="media-image">
          <h3 class="post-title">Regina's tech renaissance</h3>
          <span class="post-meta">LeaderPost - December 27, 2019</span>
        </a>
      </div>
      <div class="col-lg-4 col-12 media-post">
        <a href="https://betakit.com/11-prairie-startups-to-watch-in-2019/">
          <img src="./assets/images/media/andrewkirk.png" alt="11 Prairie Startups to watch in 2019"
            class="media-image">
          <h3 class="post-title">11 Prairie Startups to watch in 2019</h3>
          <span class="post-meta">BetaKit - February 26, 2019</span>
        </a>
      </div>
      <div class="col-lg-4 col-12 media-post">
        <a href="https://www.cbc.ca/news/canada/saskatchewan/krugo-travel-app-regina-1.4815963">
          <img src="./assets/images/media/kev.png" alt="An end to '10-tab trip planning?'" class="media-image">
          <h3 class="post-title">An end to '10-tab trip planning?'</h3>
          <span class="post-meta">CBC.ca - Sep. 8, 2018</span>
        </a>
      </div>
    </div>
    <hr class="hr2">
  </div>
</section>

<section class="videoContainer" *ngIf="!isMobile">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-4">
        <div class="row">
          <div class="col-sm-6 imgThumb" (click)="thumbClicked('tanner')">
            <img id="tannerPicture" src="./assets/images/media/tanner.png" class="img-fluid" [ngClass]="[thumbImage]">
            <div id="tannerRectangle">
              <div id="tannerName" [ngClass]="[thumbName]">Tanner</div>
            </div>
          </div>
          <div class="col-sm-6 imgThumb" (click)="thumbClicked('taylor')">
            <img id="taylorPicture" src="./assets/images/media/taylor.png" class="img-fluid " [ngClass]="[thumbImage]">
            <div id="taylorRectangle">
              <div id="taylorName" [ngClass]="[thumbName]">Taylor</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 imgThumb" (click)="thumbClicked('andrew')">
            <img id="andrewPicture" src="./assets/images/media/andrew.png" class="img-fluid" [ngClass]="[thumbImage]">
            <div id="andrewRectangle">
              <div id="andrewName" [ngClass]="[thumbName]">Andrew</div>
            </div>
          </div>
          <div class="col-sm-6 imgThumb" (click)="thumbClicked('mattea')">
            <img id="matteaPicture" src="./assets/images/media/mattea.png" class="img-fluid" [ngClass]="[thumbImage]">
            <div id="matteaRectangle">
              <div id="matteaName" [ngClass]="[thumbName]">Mattea</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 bottomThumb imgThumb" (click)="thumbClicked('dipo')">
            <img id="dipoPicture" src="./assets/images/media/dipo.png" class="img-fluid" [ngClass]="[thumbImage]">
            <div id="dipoRectangle">
              <div id="dipoName" [ngClass]="[thumbName]">Dipo</div>
            </div>
          </div>
          <div class="col-sm-6 bottomThumb imgThumb" (click)="thumbClicked('hannah')">
            <img id="hannahPicture" src="./assets/images/media/hannah.png" class="img-fluid" [ngClass]="[thumbImage]">
            <div id="hannahRectangle">
              <div id="hannahName" [ngClass]="[thumbName]">Hannah</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="iframe-container">
          <iframe [src]="selectedPerson.src | safe: 'resourceUrl'" frameborder="0" allow="autoplay; fullscreen"
            allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-sm-3 align-self-center">
        <div class="videoInfo">
          <p class="videoTitle">Best Trip Ever</p>
          <p class="videoName">{{selectedPerson.name}}</p>
          <p class="videoDescription">{{selectedPerson.writeup}}</p>
        </div>
      </div>
    </div>
    <hr class="hr">
  </div>
</section>



<section class="mobileVideoContainer" *ngIf="isMobile">
  <div class="container-fluid">
    <div class="row">
      <div class="col-4 imgThumb" (click)="thumbClicked('tanner')">
        <img id="tannerPicture" src="./assets/images/media/tanner.png" class="img-fluid" [ngClass]="[thumbImage]">
        <div id="tannerRectangle">
          <div id="tannerName" [ngClass]="[thumbName]">Tanner</div>
        </div>
      </div>
      <div class="col-4 imgThumb" (click)="thumbClicked('taylor')">
        <img id="taylorPicture" src="./assets/images/media/taylor.png" class="img-fluid " [ngClass]="[thumbImage]">
        <div id="taylorRectangle">
          <div id="taylorName" [ngClass]="[thumbName]">Taylor</div>
        </div>
      </div>
      <div class="col-4 imgThumb" (click)="thumbClicked('andrew')">
        <img id="andrewPicture" src="./assets/images/media/andrew.png" class="img-fluid" [ngClass]="[thumbImage]">
        <div id="andrewRectangle">
          <div id="andrewName" [ngClass]="[thumbName]">Andrew</div>
        </div>
      </div>
    </div>
    <div class="row imgThumb">
      <div class="col-4" (click)="thumbClicked('mattea')">
        <img id="matteaPicture" src="./assets/images/media/mattea.png" class="img-fluid" [ngClass]="[thumbImage]">
        <div id="matteaRectangle">
          <div id="matteaName" [ngClass]="[thumbName]">Mattea</div>
        </div>
      </div>
      <div class="col-4">
        <div class="greyBox">
          <img src="./assets/images/media/Account.svg" class="img-fluid grey-Box" [ngClass]="[thumbImage]">
        </div>
        <div class="comingSoon">Coming Soon</div>
      </div>
      <div class="col-4">
        <div class="greyBox">
          <img src="./assets/images/media/Account.svg" class="img-fluid grey-Box" [ngClass]="[thumbImage]">
        </div>
        <div class="comingSoon">Coming Soon</div>
      </div>
    </div>
    <div class="row mobileVideo">
      <div class="col-12">
        <div class="iframe-container">
          <iframe [src]="selectedPerson.src | safe: 'resourceUrl'" frameborder="0" allow="autoplay; fullscreen"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 align-self-center">
        <div class="videoInfo">
          <p class="videoTitle">Best Trip Ever</p>
          <p class="videoName">{{selectedPerson.name}}</p>
          <p class="videoDescription">{{selectedPerson.writeup}}</p>
        </div>
      </div>
    </div>
    <hr>
  </div>
</section>

<section class="blogPosts">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-10 col-12 blogTitle">
        <h1 class="title">Blog Posts</h1>
      </div>
    </div>

    <div class="row" id="blog">
      <div class="col-12">
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="spinner-border offset-6" role="status" [hidden]="posts">
          <span class="sr-only">Loading...</span>
        </div>

        <div class="col-12 text-center" role="status" [hidden]="!error">
          <h4>Sorry, there was a problem fetching our blog posts.</h4>
        </div>

        <div [hidden]="!posts">
          <div class="row post">
            <div class="col-lg-4 blog-post" *ngFor="let post of posts" (click)="gotoPost(post)">
              <img [src]="post.imageUrl" [alt]="post.title" class="post-image" />
              <h3 class="post-title">
                {{post.title}}
              </h3>
              <span class="post-meta">
                {{post.subtitle}} - {{post.firstPublishedAt | date}}
              </span>
            </div>
          </div>
          <div class="text-center see-all-text"><a target="_blank" href="https://medium.com/@krugo">See more</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <div class="row">
    <div class="col-12">
      <h2>Stay Up to Date</h2>
      <p>Follow us on Instagram <a href="https://www.instagram.com/krugoapp/">@krugoapp</a></p>
    </div>
    <div class="col-12">
      <div class="spinner-border offset-6" role="status" [hidden]="igPosts">
        <span class="sr-only">Loading...</span>
      </div>

      <div class="col-12 text-center" role="status" [hidden]="!error">
        <h4>Sorry, there was a problem fetching our Instagram posts.</h4>
      </div>

      <div [hidden]="!igPosts">
        <div class="row">
          <div class="col-4 ig-posts" *ngFor="let igPost of igPosts" (click)="gotoigPost(igPost)">
            <div class="image-background">
              <div class="image-container">
                <img [src]="igPost.imageUrl" class="img-fluid" id="ig-post" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->