<app-nav></app-nav>
<body>
    <section class="landing">
        <div class="container-fluid">
            <div class="row">
                <div class="col-3 col-lg-2 offset-lg-2">
                    <img src="./assets/images/icons/web-expired.png" id="hero-image" class="img-fluid"
                        alt="Krugo on iPhoneX">
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-8 offset-lg-2">
                    <h2 class="header-text">Looks like that email verification
                        link has expired.</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-8 offset-lg-2">
                    <h5 class="subheader-text">You can send a new one by going to your account >
                        edit profile > and reverifying your email. </h5>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-8 offset-lg-2">
                    <a href="{{emailExpired}}" class="btn btn-primary">Open in App</a>
                </div>
            </div>
        </div>
    </section>
</body>