<app-nav></app-nav>
<body>
    <section class="landing">
        <div class="container-fluid">
            <div class="row">
                <div class="col-3 col-lg-2 offset-lg-2">
                    <img src="./assets/images/icons/web-confirm.png" id="hero-image" class="img-fluid"
                        alt="Krugo on iPhoneX">
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-8 offset-lg-2">
                    <h2 class="header-text">Your Email has been Confirmed!</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-8 offset-lg-2">
                    <h5 class="subheader-text">Verifying your email adds an extra step of security to
                        your Krugo account. </h5>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-8 offset-lg-2">
                    <a href="{{emailVerified}}" class="btn btn-primary">Open in App</a>
                </div>
            </div>
        </div>
    </section>
</body>